import styled from 'styled-components'

export const contenedorLista = (esComputadora) => ({
    padding: "0", margin: "0",  position: 'relative',
    width: '100%'
})

export const listastyle = (esComputadora,estilos,deshabilitado) => ({
    padding: esComputadora ? "2vw 1.5vw 0.25vw 1vw":"4vw 3vw 0.5vw 2vw",
    height: "auto",
    width: "100%", /* Utiliza el ancho completo menos el espacio para el label */
    border: esComputadora ? "0.1vw solid #ccc":"0.3vw solid #ccc",
    borderRadius: esComputadora ? '0.5vw':"1vw",
    fontSize: esComputadora ? "1.3vw":"3vw",
    boxSizing: "border-box",
    backgroundColor: "white",
    ...estilos,
    ...(deshabilitado
        ? {
            backgroundColor: '#BBBBBB',
            color: "black"
          }
        : {})
})

export const opcionStyle = esComputadora => ({
  overflowY: 'scroll'
})


export const inputLabelCSS = (esComputadora,textoStyle)=>({
    position: "absolute",
    top: "50%",
    left: esComputadora ? "1vw":"2.5vw", /* Espacio para el label como un porcentaje del ancho del contenedor */
    transform: "translateY(-50%)",
    color: "#999",
    pointerEvents: "none",
    transition: "0.2s",
    fontSize: esComputadora ? "1vw":"2.5vw",
    ...textoStyle
})

export const inputLabelActivoCSS = (esComputadora)=>({
    top: "20%",
    fontSize: esComputadora ? "0.8vw":"2.0vw",
    color: "#333",
})