import styled from 'styled-components'
import * as fg from './../../Api/FuncionesGlobales'

export const estiloEslogan = esComputadora => ({
  
  display: 'flex',
  color: fg.hex_rgb('#EE1D23', 1.0),
  fontWeight: 600,
  fontStyle: 'normal',
  fontSize: esComputadora ? '2.5vw' : '5vw',
  textShadow:
    '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
  marginTop: esComputadora ? '0vw':'-1vw'
})

export const estiloContenedor = esComputadora => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  justifyContent: 'space-between', // Espacio entre los elementos
  alignItems: 'center',
  //background: "green",
  fontSize: '18px',
  width: '70%', // Ancho del 70% del body
  height: 'auto ' // Altura del 50% del body
})

export const estiloContenedorLogin = esComputadora => ({
  background: fg.hex_rgb('#6E0C0C', 0.9),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Centra verticalmente
  alignItems: 'center', // Centra horizontalmente
  width: '100%', // Mitad del ancho menos el espacio entre elementos
  height: esComputadora ? '50vh':'50vw',
  padding: '5vw',
  boxSizing: 'border-box', // El tamaño incluye el relleno y el borde, pero no el margen
  borderRadius: esComputadora ? '10px 0px 0px 10px' : '0px 00px 10px 10px'
})

export const estiloContenedorLogo = esComputadora => ({
  background: fg.hex_rgb('#908A78', 0.75),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Centra verticalmente
  alignItems: 'center', // Centra horizontalmente
  width: '100%', // Mitad del ancho menos el espacio entre elementos
  height: esComputadora ? '50vh':'40vw',
  padding: '10px',
  boxSizing: 'border-box', // El tamaño incluye el relleno y el borde, pero no el margen
  boxShadow: '4px 4px 46.6px ' + fg.hex_rgb('#000000', 0.75), // Sombra con desplazamiento x=0, y=0, blur=10px, color rgba(0, 0, 0, 0.2)
  borderRadius: esComputadora ? '0px 10px 10px 0px' : '10px 10px 0px 0px'
})

export const estiloCabecera = esComputadora => ({
  color: fg.hex_rgb('#ffffff', 1.0),
  fontSize: esComputadora ? '3.5vw' : '6vw', // Tamaño de fuente relativo al ancho de la ventana
  fontFamily: 'Pridi, serif', // Cambia "Arial, sans-serif" por la fuente que desees
  fontWeight: 'bold', // Puedes usar "bold", "normal", "lighter", "bolder", etc.
  textAlign: 'center', // Centra el texto horizontalmente
  display: 'flex',
  justifyContent: 'center', // Centra el contenido horizontalmente
  alignItems: 'center' // Centra el contenido verticalmente
})


export const estilosError = esComputadora => ({
  color: 'black',
  fontSize: esComputadora ? '1vw':'2.5vw',
  fontWeight: 'bold',
  //display: 'block',
  textAlign: 'center',
  width: '100%',
  padding: esComputadora ? '1vw':'2.5vw',
  boxSizing: 'border-box',
  margin: '0px',
  backgroundColor: '#f96969',
  fontFamily: 'Arial, serif' // Cambia "Arial, sans-serif" por la fuente que desees
})
export const estiloVacio = (esComputadora, vacio) => ({
  
  //borderColor: usuarioVacio ? 'red':fg.hex_rgb("#ffffff",0),
  border: vacio ? `${esComputadora ? "0.1vw":"0.2vw"} solid red` : `${esComputadora ? "0.1vw":"0.2vw"} solid #ccc`,
  boxShadow: vacio ? `inset 0 0 ${esComputadora ? "0.5vw":"1vw"} red` : `inset 0 0 ${esComputadora ? "0.5vw":"1vw"} gray`
})
