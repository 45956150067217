//import './App.css';
import React from 'react';

/*
function hexToRGBA(hex, opacidad) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`;
}
*/
function App() {
  return (
    <div >
    </div>
  );
}

export default App;