import styled, { keyframes } from 'styled-components';

export const animacionSpinner = (esComputadora) => (
    keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
)
export const StyledSpinner =
    styled.div`
    box-sizing: border-box;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: ${props => animacionSpinner(props.esComputadora)} 1s linear infinite;
    margin: auto;
    `

export const Spinner = (param) =>(
  <StyledSpinner esComputadora = {param.esComputadora} />
)
