import React, { useState, useEffect } from 'react'
import {
  listaDistribuidores,
  listarAyudantesDistribuidor,
  ultimoPedido,
  actualizarPedido,
  solicitarPedido
} from './../../../../Api/Api'
import Opciones from './../../../../Elementos/Opciones'
import Input from './../../../../Elementos/Input'
import Boton from './../../../../Elementos/Boton'

function GenerarPedidoDist (datos) {
  const [isFocused, setIsFocused] = useState(false)
  const [distribuidores, setDistribuidores] = useState(null)
  const [distribuidorSeleccionado, setDistribuidorSeleccionado] = useState(null)
  const [ayudantes, setAyudantes] = useState(null)
  const [ayuDistSelec, setayuDistSelec] = useState(null)
  const [fecRecojo, setfecRecojo] = useState(null)
  const [ultimosPedidos, setUltimosPedidos] = useState(null)
  const [codPedido, setCodPedido] = useState(null)
  const [subTotales, setSubTotales] = useState([])
  const [estaProcesando, setestaProcesando] = useState(false)
  const [solicitoPedido, setSolicitoPedido] = useState(false)

  useEffect(() => {
    const listaDistribuidoresData = async () => {
      const respuesta = await listaDistribuidores() // Esta función debe retornar el resultado que necesitas para la validación
      //const respuesta2 = await listarPedidos("",1,1);
      if (respuesta) {
        if (respuesta.VPListaErrores.length === 0) {
          setDistribuidores(respuesta.Distribuidores)
        }
        /*if(respuesta2.VPListaErrores.length ===0){
          setPedidos(respuesta2.Pedidos)
          //console.log(respuesta2)
        }*/
      }
    }
    listaDistribuidoresData()
  }, [])

  const distribuidorSeleccionar = async valor => {
    setDistribuidorSeleccionado(valor)
  }

  useEffect(() => {
    if (distribuidorSeleccionado !== null) {
      async function fetchData () {
        const respuesta = await listarAyudantesDistribuidor(
          distribuidorSeleccionado
        )
        const respuesta2 = await ultimoPedido(distribuidorSeleccionado)
        if (respuesta) {
          if (respuesta.VPListaErrores.length === 0) {
            setAyudantes(respuesta.ayudanteDistribuidores)
          } else {
            setAyudantes(null)
          }
        }
        if (respuesta2) {
          if (respuesta2.VPListaErrores.length === 0) {
            setUltimosPedidos(respuesta2)
            setCodPedido(respuesta2.codPedido)
          } else {
            setUltimosPedidos(null)
            setayuDistSelec(null)
          }
        }
      }
      fetchData()
    } else {
      setayuDistSelec(null)
    }
  }, [distribuidorSeleccionado])

  const ayudanteDistribuidor = async valor => {
    setayuDistSelec(valor)
  }

  useEffect(() => {
    if (ayuDistSelec !== null) {
      async function fetchData () {
        const respuesta = await ultimoPedido(ayuDistSelec)
        if (respuesta) {
          if (respuesta.VPListaErrores.length === 0) {
            setUltimosPedidos(respuesta)
            //const subTotalesTemp = respuesta.pedidos.
            //setSubTotales([...subTotales,prod.subtotalSolicitada])
            setCodPedido(respuesta.codPedido)
            //setAyudantes(respuesta.ayudanteDistribuidores);
          } else {
            const respuesta2 = await ultimoPedido(distribuidorSeleccionado)
            if (respuesta2) {
              if (respuesta2.VPListaErrores.length === 0) {
                setUltimosPedidos(respuesta2)
                setCodPedido(respuesta2.codPedido)
                setayuDistSelec(null)
              } else {
                setUltimosPedidos(null)
              }
            }
          }
        }
      }
      fetchData()
    }
  }, [ayuDistSelec])

  const listaDistStyle = {
    boxSizing: 'border-box',
    width: '100%',
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 5' : '1 / 1 / 2 / 11'
  }
  const listaAyuDistStyle = {
    boxSizing: 'border-box',
    width: '100%',
    gridArea: datos.esComputadora ? '1 / 6 / 2 / 11' : '1 / 12 / 2 / 21'
  }

  const contenedorUltimosPedidos = {
    gridArea: datos.esComputadora ? '3 / 1 / 19 / 21' : '3 / 1 / 19 / 21',
    backgroundColor: '#F4F4F4',
    overflow: 'auto',
    boxSizing: 'border-box',
    marginTop: '10px',
    border: '1px solid #ccc',
    padding: '0 10px 0 10px',
    borderRadius: '10px'
  }
  const contenedorTotalesStyle = {
    display: 'grid',
    gridTemplateColumns: '50% repeat(4,1fr)',
    gridTemplateRows: '100%',
    gridArea: datos.esComputadora ? '19 / 1 / 21 / 21' : '19 / 1 / 21 / 21',
    backgroundColor: '#F4F4F4',
    //overflow: "auto",
    boxSizing: 'border-box',
    marginTop: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '10px',
    textAlign: 'center'
  }
  const cardPedidostyle = {
    backgroundColor: 'white', //"#FCFCFC",
    display: 'inline-block',
    //clear: "both",
    borderRadius: '20px',
    margin: '20px 10px 0 0',
    //padding: "10px",
    overflow: datos.esComputadora ? 'auto' : 'none',
    height: datos.esComputadora ? '250px' : '',
    boxSizing: 'border-box',
    width: datos.esComputadora ? 'calc(100%/3.12)' : '100%',
    border: '1px solid #ccc',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)'
  }
  const nomCatProdStyle = {
    display: 'block',
    backgroundColor: '#AD6726',
    color: 'white',
    fontSize: datos.esComputadora ? '1vw' : '1.7vh',
    //color: "#363636",
    fontWeight: 'bolder',
    //marginBottom: "5px",
    textAlign: 'Center',
    borderRadius: '10px 10px 0px 0px',
    padding: '5px'
  }
  const contProdStyle = {
    padding: '0 10px 5px 10px ',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 18px 18px'
  }
  const contCabProductoStyle = {
    display: 'grid',
    gridTemplateColumns: '30% repeat(4,1fr)', //"repeat(5,1fr)",
    gridTemplateRows: '100%',
    fontWeight: '800',
    //marginTop: "10px",
    //backgroundColor: "yellow",
    fontSize: datos.esComputadora ? '0.9vw' : '1.6vh',
    color: '#868686',
    textAlign: 'center'
  }
  const contProductoStyle = {
    display: 'grid',
    gridTemplateColumns: '30% repeat(4,1fr)',
    gridTemplateRows: '100%',
    marginTop: '5px',
    //backgroundColor: "yellow",
    fontSize: datos.esComputadora ? '0.9vw' : '1.6vh',
    color: '#868686'
  }

  const descProdStyle = {
    gridArea: '1/1/1/2',
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'left',
    //justifySelf: "center",
    alignItems: 'center',
    color: '#737373',
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
    fontWeight: '500'
  }
  const precProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const ultPedProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const pedProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const subToPedStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373',
    fontWeight: '900'
  }
  const guardarPedidoStyle = {
    gridArea: datos.esComputadora ? '1 / 4 / 2 / 6' : '1 / 4 / 2 / 6',
    fontSize: datos.esComputadora ? '1vw' : '2vw',
    textAlign: 'right',
    marginRight: '2vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const labelTotPeStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }
  const labelTotEnStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }
  const contFecPedStyle = {
    gridArea: datos.esComputadora ? '1 / 12 / 2 / 16' : '2 / 1 / 2 / 11',
    padding: '0',
    margin: datos.esComputadora ? '0' : '1vw 0px 0px 0px',
    boxSizing: 'border-box',
    width: datos.esComputadora ? '100%' : '100%'
  }
  const contFecRecStyle = {
    gridArea: datos.esComputadora ? '1 / 17 / 2 / 21' : '2 / 12 / 2 / 21',
    margin: datos.esComputadora ? '0' : '1vw 0px 0px 0px',
    boxSizing: 'border-box',
    width: datos.esComputadora ? '100%' : '100%'
  }
  const inputFecStyle = {
    height: '1px',
    padding: '15px 10px 5px',
    height: '100%',
    marginTop: '-5px',
    marginLeft: '-10%',
    width: '90%',
    borderRadius: '10px',
    border: '1px solid #ccc',
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)'
  }
  const labelFecStyle = {
    fontSize: datos.esComputadora ? '0.9em' : '1.6vh',
    marginLeft: '-10%'
  }
  const pedido = {
    color: 'red',
    padding: '1px',
    margin: '0',
    width: '100%',
    fontSize: datos.esComputadora ? '1vw' : '2vw',
    textAlign: 'center',
    border: '0.1vw solid #cccccc'
  }
  const totalStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: "center",
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 2' : '1 / 1 / 2 / 2',
    marginRight: '10px',
    fontSize: datos.esComputadora ? '1.5vw' : '3vw',
    fontWeight: '500',
    textAlign: 'right'
  }
  const cantTotalStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: "center",
    gridArea: datos.esComputadora ? '1 / 2 / 2 / 3' : '1 / 2 / 2 / 3',
    fontWeight: '500',
    fontSize: datos.esComputadora ? '2vw' : '3.5vw',
    textAlign: 'left'
  }
  const cambioFecPedido = event => {
    event.target.value = obtenerFechaActual()
  }

  const cambioCantPedido = async event => {
    console.log('CAMBIO')
    const respuesta = await actualizarPedido(
      //ayuDistSelec ? ayuDistSelec : 
      distribuidorSeleccionado,
      parseInt(event.target.id),
      codPedido,
      isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)
    )
    if (respuesta) {
      if (respuesta.VPListaErrores.length === 0) {
        const subTotaltemp = document.getElementById(
          respuesta.codProducto + '' + respuesta.codPedido
        )
        const totalTemp = document.getElementById('cantTotal')

        subTotaltemp.textContent = formatearMonedaSoles(
          respuesta.subtotalPedido
        )
        totalTemp.textContent = formatearMonedaSoles(respuesta.totalPedido)
      }
    }
  }
  const cambioFecRecojo = event => {
    //onsole.log(event.target.value)
    setfecRecojo(event.target.value)
  }
  const solicitarPedidoClick = async event => {
    setestaProcesando(true)
    try {
      if (fecRecojo !== null && fecRecojo !== '') {
        const respuesta = await solicitarPedido(
          ayuDistSelec ? ayuDistSelec : distribuidorSeleccionado,
          codPedido,
          fecRecojo + 'T' + obtenerHoraActual(),
          1
        )
        if (respuesta) {
          if (respuesta.VPListaErrores.length === 0) {
            setestaProcesando(false)
            window.location.reload()
          } else {
            setestaProcesando(false)
          }
        }
      } else {
        setestaProcesando(false)
        setSolicitoPedido(true)
        console.log('entre')
        console.log(fecRecojo)
      }
    } catch (error) {
      setestaProcesando(false)
    }
  }
  return (
    <>
      <div id='contenedorFechaPedido' style={contFecPedStyle}>
        <Input
          style={{ fontWeight: 'bolder' }}
          textoStyle={{
            fontWeight: 'bolder',
            fontSize: datos.esComputadora ? '1.2vw' : '2.5vw',
          }}
          deshabilitadoStyle={{
            //backgroundColor: 'white'
            fontSize: datos.esComputadora ? '1.2vw' : '2.5vw',
          }}
          id='fechaPedido'
          moderno='Fecha de Pedido'
          tipo='date'
          autoComplete
          onChange={cambioFecPedido}
          valorInicial={obtenerFechaActual()}
          deshabilitado
          esComputadora={datos.esComputadora}
        />
      </div>
      <div id='contenedorFechaRecojo' style={contFecRecStyle}>
        <Input
          style={{
            fontWeight: 'bolder',
            fontSize: datos.esComputadora ? '1.2vw' : '2.5vw',
            border:
              solicitoPedido && (fecRecojo === null || fecRecojo === '')
                ? '1px solid red'
                : '1px solid #ccc',
            boxShadow:
              solicitoPedido && (fecRecojo === null || fecRecojo === '')
                ? 'inset 0 0 5px red'
                : 'inset 0 0 1px gray'
          }}
          textoStyle={{
            fontWeight: 'bolder',
            fontSize: datos.esComputadora ? '1.2vw' : '2.5vw',
          }}
          id='fechaPedido'
          moderno='Fecha de Recojo'
          tipo='date'
          autoComplete
          estiloLabel={labelFecStyle}
          onChange={cambioFecRecojo}
          valorInicial={''}
          esComputadora={datos.esComputadora}
        />
      </div>
      <div id='contenedorListaDist' style={listaDistStyle}>
        <Opciones
          moderno='Distribuidor'
          opciones={distribuidores !== null ? distribuidores : []}
          clave='codUsuario'
          valor={['Nombre', 'ApellidoPaterno']}
          esComputadora={datos.esComputadora}
          onClick={distribuidorSeleccionar}
        />
      </div>
      {/* <div id='contenedorListaAyuDist' style={listaAyuDistStyle}>
        <Opciones
          moderno='Ayudante Distribuidor'
          opciones={ayudantes !== null ? ayudantes : []}
          clave='codUsuario'
          valor={['Nombre', 'ApellidoPaterno']}
          esComputadora={datos.esComputadora}
          onClick={ayudanteDistribuidor}
        />
      </div> */}
      <div id='contenedorUltimosPedidos' style={contenedorUltimosPedidos}>
        {ultimosPedidos !== null &&
          ultimosPedidos.ProductosPedidosPorCategoria.map(pppc => {
            return (
              <div
                key={pppc.codCategoria}
                id={pppc.descCategoria}
                style={cardPedidostyle}
              >
                <div style={nomCatProdStyle}> {pppc.descCategoria} </div>
                <div style={contProdStyle}>
                  <div style={contCabProductoStyle}>
                    <div>Producto</div>
                    <div>Precio x Unidad</div>
                    <div>Ultimo Pedido</div>
                    <div>Pedido</div>
                    <div>Sub Total</div>
                  </div>
                  {pppc.productos.map(prod => {
                    return (
                      <div key={prod.codProducto} style={contProductoStyle}>
                        <div style={descProdStyle}>{prod.descProducto}</div>
                        <div style={precProdStyle}>
                          {formatearMonedaSoles(prod.precioUnitario)}
                        </div>
                        <div style={ultPedProdStyle}>{prod.canPedAnterior}</div>
                        <div style={pedProdStyle}>
                          <Input
                            style={{
                              ...pedido
                            }}
                            antiguo='' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                            tipo='number'
                            onBlur={cambioCantPedido}
                            onChange={(event) => {
                              if(event.currentTarget.value==="")
                                cambioCantPedido(event)
                            }}
                            id={prod.codProducto}
                            valorInicial={
                              prod.canSolicitada !== 0 ? prod.canSolicitada : ''
                            }
                            
                            estadoCambio={codPedido !== 0 ? codPedido : ''}
                          />
                        </div>
                        <div
                          id={prod.codProducto + '' + codPedido}
                          style={subToPedStyle}
                        >
                          {formatearMonedaSoles(prod.subtotalSolicitada)}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
      <div id='contenedorTotales' style={contenedorTotalesStyle}>
        <div id='total' style={totalStyle}>
          TOTAL:
        </div>
        <div id='cantTotal' style={cantTotalStyle}>
          {ultimosPedidos !== null &&
            formatearMonedaSoles(ultimosPedidos.totalPedido)}
        </div>
        <div id='guardarPedido' style={guardarPedidoStyle}>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
              padding: datos.esComputadora ? '0.8vw' : '2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: datos.esComputadora ? '1vw' : '2vw'
            }}
            texto='Solicitar Pedido'
            tipo='submit'
            tonalidad='20'
            estaProcesando={estaProcesando}
            onClick={solicitarPedidoClick}
          />
        </div>
      </div>
    </>
  )
}

function capitalizeWords (str) {
  let words = str.toLowerCase().split(' ')
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
  return capitalizedWords.join(' ')
}

function formatDateTime (dateTimeStr) {
  const dateObj = new Date(dateTimeStr) // Crear un objeto Date desde la cadena

  const day = String(dateObj.getDate()).padStart(2, '0')
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const year = String(dateObj.getFullYear()).slice(-2) // Obtener los últimos dos dígitos del año

  const formattedDate = `${day}/${month}/${year}`
  let hours = dateObj.getHours()
  const minutes = String(dateObj.getMinutes()).padStart(2, '0')
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours = hours % 12
  hours = hours ? hours : 12 // '0' debería ser '12' en el formato de 12 horas

  const formattedTime = `${hours}:${minutes} ${ampm}`
  //${formattedTime}
  return `${formattedDate}`
}
function obtenerHoraActual () {
  const ahora = new Date()
  const horas = ahora.getHours().toString().padStart(2, '0')
  const minutos = ahora.getMinutes().toString().padStart(2, '0')
  const segundos = ahora.getSeconds().toString().padStart(2, '0')
  return `${horas}:${minutos}:${segundos}`
}
function obtenerFechaActual () {
  let fechaActual = new Date()
  let año = fechaActual.getFullYear()
  let mes = ('0' + (fechaActual.getMonth() + 1)).slice(-2) // Asegura 2 dígitos
  let dia = ('0' + fechaActual.getDate()).slice(-2) // Asegura 2 dígitos
  return `${año}-${mes}-${dia}`
}
function formatearMonedaSoles (valor) {
  return valor.toLocaleString('es-PE', {
    style: 'currency',
    currency: 'PEN'
  })
}
function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}
export default GenerarPedidoDist
