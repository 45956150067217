import React, { useEffect, useState } from 'react'
import * as e from './BotonCSS'
import * as fg from '../Api/FuncionesGlobales.js'

const Boton = (param) => {
  const [isHovered, setIsHovered] = useState(false)

  const botonCSS = {
    border: 'none',
    textDecoration: 'none',
    cursor: 'pointer',
    transitionDuration: '0.2s',
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',

    ...{
      ...param.style,
      backgroundColor: fg.colorBotonGenerico() === ''
                          ? param.style ?
                          param.style.backgroundColor :'#CCCCCC'
                          : fg.colorBotonGenerico()
    },
    ...(isHovered && {
      backgroundColor: fg.rbga_tonalidad(
        fg.colorBotonGenerico() === ''
                          ? param.style ?
                          param.style.backgroundColor :'#CCCCCC'
                          : fg.colorBotonGenerico(),
        param.tonalidad
      )
    }),
    ...(param.estaProcesando && {
      backgroundColor: '#EBEBE400',
      cursor: 'wait',
      color: '#4c4c4ccc'
    }),
    gridArea: param.ubicacion ? param.ubicacion : '',
    ...(param.deshabilitado ? {
      backgroundColor: '#ccc',
      cursor:'not-allowed'
    }:{})
  }
  return (
    <button
      id={param.id ? param.id : ''}
      style={{
        ...botonCSS
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={param.estaProcesando ? undefined : param.onClick}
      type={param.tipo ? param.tipo : ''}
      disabled={param.estaProcesando || param.deshabilitado}
    >
      {param.estaProcesando ? 
        <e.Spinner esComputadora={param.esComputadora} />
       : 
        <div style={param.textoStyle}>{param.texto}</div>
      }
    </button>
  )
}

export default Boton;
