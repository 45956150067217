import styled, {keyframes} from "styled-components"
import * as fg from './../../../../Api/FuncionesGlobales'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

// Crear el contenedor del spinner, usando flexbox para alinear verticalmente el spinner y el mensaje
export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column; /* Alinea el spinner y el mensaje en una columna */
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  height: 100%; /* Ocupa toda la altura de la ventana */
  text-align: center; /* Centra el texto horizontalmente */
`

// Crear el spinner con colores personalizados usando transient props
export const Spinner = styled.div`
  box-sizing: border-box;
  border: 7px solid ${props => props.$backgroundcolor || '#d3d3d3'}; /* Color del fondo del spinner */
  border-top: 7px solid ${props => props.$spinnercolor || '#00f'}; /* Color del spinner en movimiento */
  border-radius: 50%;
  width: ${props => props.$dimensiones || '#00f'};
  height: ${props => props.$dimensiones || '#00f'};
  animation: ${spin} 1s linear infinite;
  margin-bottom: 16px; /* Espacio entre el spinner y el mensaje */
`

export const LoadingSpinner = ({
  dimensiones = '50px',
  spinnercolor = '#00f',
  backgroundcolor = '#d3d3d3',
  mensaje = 'Cargando información, espera un momento'
}) => {
  return (
    <SpinnerContainer id='contenedorProcesoDetalle'>
      <Spinner
        id='loadingProcesando'
        $spinnercolor={spinnercolor}
        $backgroundcolor={backgroundcolor}
        $dimensiones={dimensiones}
      />
      <div>{mensaje}</div>
    </SpinnerContainer>
  )
}

export const listaDistStyle = (esComputadora,rol) => ({
  padding: "0 1vw 0 1vw",
  display:
    rol !== 'CLIENTE'
      ? rol === 'AYUDANTE DE DISTRIBUIDOR'
        ? 'none'
        : ''
      : 'none',
  boxSizing: 'border-box',
  width: '100%',
  gridArea: esComputadora ? '1 / 1 / 2 / 10' : '1 / 1 / 2 / 11'
})
export const listaAyuDistStyle = (esComputadora,rol) => ({
  padding: "0 1vw 0 1vw",
  display: rol === 'AYUDANTE DE DISTRIBUIDOR' ? 'none' : '',
  boxSizing: 'border-box',
  width: '100%',
  gridArea: esComputadora ? '1 / 11 / 2 / 21' : '1 / 12 / 2 / 21'
})
export const contenedorListaPedidosstyle = (esComputadora) => ({
  overflow: 'auto',
  gridArea: esComputadora ? '3 / 1 / 21 / 21' : '3 / 1 / 21 / 21'
})
export const contenedorNombreYPedido = (esComputadora) => ({
  display: 'flex'
})
export const nomDistStyle = (esComputadora) => ({
  fontSize: esComputadora ? "1vw":"2.2vw",
  color: '#363636',
  fontWeight: 'bolder',
  marginBottom: '5px',
  alignItems: 'flex-start'
})
export const nroPedidoStyle = (esComputadora) => ({
  marginLeft: 'auto',
  fontWeight: 'bolder',
  color: '#363636',
  fontSize: esComputadora ? "1vw":"2.5vw",
})

export const contUsrRecStyle = (esComputadora) => ({
  display: 'block',
  fontSize: esComputadora ? "1vw":"2vw",
  color: '#868686'
})
export const usrRecStyle = (esComputadora) => ({
  display: 'inline-block',
  //backgroundColor: "orange",
  fontSize: '0.9em !important',
  color: '#737373',
  fontWeight: '700',
  marginRight: '5px'
})
export const contFecSolStyle = (esComputadora) => ({
  display: 'inline-block',
  //backgroundColor: "purple",
  fontSize: esComputadora ? "1vw":"2vw",
  width: 'calc(100%/3)',
  paddingRight: '5px',
  boxSizing: 'border-box',
  textAlign: 'center',
  color: '#737373'
})
export const labFecSolStyle = (esComputadora) => ({
  display: 'flex',
  //backgroundColor: "#17BDBB",
  height: '6vh',
  justifyContent: 'center' /* Centra horizontalmente */,
  alignItems: 'center',
  textAlign: 'center',
  color: '#737373',
  fontWeight: '700',
  marginTop: '5px',
  marginBottom: esComputadora ? '3px' : '-15px',
  fontSize: esComputadora ? "1vw":"2vw"
})
export const contenedorTotales = (esComputadora) => ({
  display: 'grid',
  gridTemplateRows: '100%',
  gridTemplateColumns: '33% 33% 33%',
  marginTop: '1vw',
  marginBottom: '-0.6vw'
})
export const contTotPeStyle = (esComputadora) => ({
  //backgroundColor: "Pink",
  fontSize: '0.8em ',
  gridArea: '1/1/2/2',
  textAlign: 'center',
  color: '#737373'
})
export const contTotEnStyle = (esComputadora) => ({
  //backgroundColor: "Brown",
  fontSize: '0.8em ',
  gridArea: '1/3/2/4',
  textAlign: 'center',
  //marginRight: "10%",
  color: '#737373'
})
export const contMediumStyle = (esComputadora) => ({
  //backgroundColor: "Brown",
  fontSize: '0.8em ',
  gridArea: '1/2/2/3',
  textAlign: 'center',
  //marginRight: "10%",
  color: '#737373'
})
export const labelTotPeStyle = (esComputadora) => ({
  display: 'block',
  color: '#737373',
  fontWeight: '700',
  fontSize: esComputadora ? "1vw":"2vw",
})
export const labelMediumStyle = (esComputadora) => ({
  display: 'block',
  color: '#737373',
  fontWeight: '700'
})
export const labelTotEnStyle = (esComputadora) => ({
  display: 'block',
  color: '#737373',
  fontWeight: '700',
  fontSize: esComputadora ? "1vw":"2vw"
})

export const estiloHover = (esComputadora,isHovered) => ({
  ...(isHovered && {
    cursor: 'pointer'
    //backgroundColor: hexToRGBA('#DCAD2F', 1.0),
    //boxShadow: 'inset 0 0 22px #FFFFFF',
    //...(!datos.esComputadora && { borderBottom: '5px solid #603711' }),
    //...(datos.esComputadora && { borderLeft: '5px solid #603711' })
  })
})
export const cardPedidostyle = (esComputadora,isHovered) => ({
  //backgroundColor: "#FFF7A9",//"#FCFCFC",
  display: esComputadora ? 'inline-block' : '',
  borderRadius: '20px',
  margin: '20px 10px 0px 0px',
  padding: '10px',
  boxSizing: 'border-box',
  width: esComputadora ? 'calc(100%/3.15)' : '100%',
  border: '1px solid #ccc',
  boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
  ...(isHovered ? estiloHover : '')
})

export const modalStyle = (esComputadora) => ({
  position: 'absolute',
  left: '0px',
  top: '0px',
  width: '100vw',
  height: '100vh',
  backgroundColor: fg.hex_rgb('#C1C1C1', 0.8),
  zIndex: '20',
  boxSizing: 'border-box'
})
export const contContenidoModalStyle = (esComputadora) => ({
  position: 'relative',

  width: '90vw',
  height: '90vh',
  left: '5vw',
  boxSizing: 'border-box',
  top: '5vh'
})
export const contenidoModalStyle = (esComputadora) => ({
  display: 'grid',
  gridTemplateRows: 'repeat(20, 1fr)',
  gridTemplateColumns: 'repeat(20, 1fr)',
  width: '90vw',
  height: '90vh',
  overflow: 'auto',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  borderRadius: esComputadora ? "1vw":'2vw',
  padding: esComputadora ? "1vw":'2vw',
})

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
})

export const cajaTotalStyle = (esComputadora) => ({
  marginTop: "1vw",
  //backgroundColor: "green",
  boxSizing: 'border-box',
  width: '100%',
  gridArea: esComputadora ? '2 / 1 / 3 / 21' : '2 / 1 / 3 / 21',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
export const cantTotalStyle = (esComputadora) => ({
  display: 'inline-block',
  fontWeight: '500',
  fontSize: esComputadora ? '2vw' : '2vh',
  textAlign: 'left'
})
export const botonEliminarStyle = esComputadora => ({
  gridArea: '1/3/2/4',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer'
  //filter: "drop-shadow(0.5px 0.5px 0.5px rgba(1, 1, 1, 0.9))",
})

export const imagenBotonEliminarStyle = esComputadora => ({
  width: esComputadora ? '1.5vw' : '2.6vh',
  height: 'auto'
})

export const numDistStyle = esComputadora => ({
  
  padding: "0 1vw 0 1vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //backgroundColor: "green",
  boxSizing: 'border-box',
  width: '100%',
  fontSize: esComputadora ? "2vw": "3.5vw",
  fontWeight: "900",
  gridArea: esComputadora ? '1 / 10 / 2 / 20' : '1 / 11 / 2 / 21',
  color: "#737373"
})