import React, { useState, useEffect, useRef } from 'react'
import * as estilos from './AsistenciasCSS'
import Boton from '../../../../Elementos/Boton'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  PDFDownloadLink

} from '@react-pdf/renderer'

import { 
  hexToRGBA,
  formatearFecha2,
  capitalizeWords,
  formatearFecha3,
  formatearMonedaSoles
 } from './../../../../Api/Api'

 import * as fg from './../../../../Api/FuncionesGlobales'

function AsistenciaPDF (datos) {
  //const [verPdf2, setVerPdf] = useState(datos.verpdf)
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rangoFechas, setrangoFechas] = useState(datos.rangoFechas)
  const [asistenciaDetalleData, setAsistenciaDetData] = useState(datos.asistenciaDetalleData)

  useEffect(
    ()=>{
      console.log(asistenciaDetalleData)
      console.log(navigator.userAgent)
    }, []
  )

  const DocumentoPdfAsistencia = ()=>(
    <Document>
                  <Page size='A4'>
                    <View style={estilos.styles.table}>
                    <View style={estilos.styles.tableCol}>
                        <View style={[estilos.styles.rowspanCell,{height: "25px", width: "42.9%",fontSize: "11px",borderTopWidth:1}]}>
                          <Text>Datos</Text>
                        </View>
                        <View style={estilos.styles.tableRow}>
                          <View style={estilos.styles.tableCol}>
                            
                            <View style={[estilos.styles.tableCellTitulo,{borderTopWidth:1}]}>
                              <Text>Lunes </Text>
                              <Text>{rangoFechas!==null ? formatearFecha2(rangoFechas[0]):""}</Text>
                            </View>
                            <View style={[estilos.styles.tableCellTitulo,{borderTopWidth:1}]}>
                              <Text>Martes</Text>
                              <Text>{rangoFechas!==null ? formatearFecha2(rangoFechas[1]):""}</Text>
                            </View>
                            <View style={[estilos.styles.tableCellTitulo,{borderTopWidth:1}]}>
                              <Text>Miercoles</Text>
                              <Text>{rangoFechas!==null ? formatearFecha2(rangoFechas[2]):""}</Text>
                            </View>
                            <View style={[estilos.styles.tableCellTitulo,{borderTopWidth:1}]}>
                              <Text>Jueves</Text>
                              <Text>{rangoFechas!==null ? formatearFecha2(rangoFechas[3]):""}</Text>
                            </View>
                            <View style={[estilos.styles.tableCellTitulo,{borderTopWidth:1}]}>
                              <Text>Viernes</Text>
                              <Text>{rangoFechas!==null ? formatearFecha2(rangoFechas[4]):""}</Text>
                            </View>
                            <View style={[estilos.styles.tableCellTitulo,{borderTopWidth:1}]}>
                              <Text>Sábado</Text>
                              <Text>{rangoFechas!==null ? formatearFecha2(rangoFechas[5]):""}</Text>
                            </View>
                            <View style={[estilos.styles.tableCellTitulo,{borderTopWidth:1}]}>
                              <Text>Domingo</Text>
                              <Text>{rangoFechas!==null ? formatearFecha2(rangoFechas[6]):""}</Text>
                            </View>
                          </View>
                          
                        </View>
                      </View>
                      {asistenciaDetalleData!==null && asistenciaDetalleData.map((asistenciaDetalleElemento)=>(
                        <View key={asistenciaDetalleElemento.usuario} style={estilos.styles.tableCol}>
                          <View style={[estilos.styles.rowspanCell,{backgroundColor: "#ccc",justifyContent:"flex-start"}]}>
                            <Text>{capitalizeWords(asistenciaDetalleElemento.persona.nombre+' '+
                                                      asistenciaDetalleElemento.persona.apellido_paterno+' ' +
                                                      asistenciaDetalleElemento.persona.apellido_materno+' '  
                                                  )}</Text>
                          </View>
                          <View style={estilos.styles.tableRow}>
                            <View style={estilos.styles.tableCol}>
                              <View style={estilos.styles.tableCell}>
                                <Text>Hora Ingreso</Text>
                              </View>
                              {
                                    asistenciaDetalleElemento!==null && asistenciaDetalleElemento.asistencias.map((asistencia)=>(
                                      <View key={asistencia.id} style={estilos.styles.tableCell}>
                                        <Text>{asistencia.ingreso ?  formatearFecha3(asistencia.ingreso) :'-'}</Text>
                                      </View>
                                    ))
                              }
                            </View>
                            <View style={estilos.styles.tableCol}>
                              <View style={estilos.styles.tableCell}>
                                <Text>Hora Salida</Text>
                              </View>
                              {
                                asistenciaDetalleElemento!==null && asistenciaDetalleElemento.asistencias.map((asistencia)=>(
                                  <View key={asistencia.id} style={estilos.styles.tableCell}>
                                    <Text>{asistencia.salida ?  formatearFecha3(asistencia.salida) :'-'}</Text>
                                  </View>
                                ))
                              }
                            </View>
                            <View style={estilos.styles.tableCol}>
                              <View style={estilos.styles.tableCell}>
                                <Text>Pago</Text>
                              </View>
                              {
                                asistenciaDetalleElemento!==null && asistenciaDetalleElemento.asistencias.map((asistencia)=>(
                                  <View key={asistencia.id} style={estilos.styles.tableCell}>
                                    <Text>{asistencia.monto_pago ?  formatearMonedaSoles(asistencia.monto_pago) :'-'}</Text>
                                  </View>
                                ))
                              }
                            </View>
                            <View style={estilos.styles.tableCol}>
                              <View style={[estilos.styles.tableCell,{width: "13.6%", backgroundColor: "#ccc", fontWeight: "bold"}]}>
                                <Text>Pago Total</Text>
                              </View>
                              <View style={[estilos.styles.tableCell,{width: "95%", backgroundColor: "#ccc"}]}>
                                <Text>{asistenciaDetalleElemento.pago ? formatearMonedaSoles(asistenciaDetalleElemento.pago) :'-'}</Text>
                              </View>
                              
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>
                  </Page>
                </Document>
  )
  return (
    <>
      {console.log(datos.esComputadora)}
      <div
        id='contenedorPDF'
        style={estilos.contenedorPdf(datos.esComputadora)}
      >
        {datos.verpdf && (
          <div id='pdfVista' style={estilos.pdfStyleModal(datos.esComputadora)}>
            <div style={{gridArea: "1/2/2/10", backgroundColor: "green", padding: "2px",marginTop:"5px",borderRadius: "5px", textAlign: "center"}}>
              <PDFDownloadLink style={{color: "white", width: "100%", textDecoration: "none"}} document={<DocumentoPdfAsistencia/>} fileName={`reporteAsistencias_${fg.fecha_hora_actual()}.pdf`}>
                {({ blob, url, loading, error }) =>
                  loading ? 'Cargando documento...' : 'Descargar Reporte'
                }
              </PDFDownloadLink>
            </div>
            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
            
              <Boton
                style={{
                  backgroundColor: '#E12121',
                  borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                  padding: datos.esComputadora ? '0.8vw' : '2vw'
                }}
                textoStyle={{
                  color: 'white',
                  width: '100%',
                  fontSize: datos.esComputadora ? '1vw' : '2.5vw',
                }}
                texto='X'
                tipo='submit'
                color={hexToRGBA('#E12121', 1)}
                tonalidad='20'
                estaProcesando={estaProcesando}
                onClick={datos.cerrarPdfModalClick}
              />
            </div>
            <div style={estilos.pdfContenidoStyle(datos.esComputadora)}>
              <PDFViewer style={{ width: '89.5vw', height: '85vh' }}>
                <DocumentoPdfAsistencia/>
                
              </PDFViewer>
            </div>
          </div>
        )}
      </div>
      
    </>
  )
}

export default AsistenciaPDF
