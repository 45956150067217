import React, { useState, useEffect, useRef } from 'react'
import editar from './../../../../Imagenes/Iconos/editar.png'
import borrar from './../../../../Imagenes/Iconos/borrar.png'
import * as estilos from './ModalVerPagoPedidoCSS'
import Boton from '../../../../Elementos/Boton'
import { Page, Text, View, Document, PDFViewer } from '@react-pdf/renderer'

import {
  hexToRGBA,
  formatearFecha2,
  capitalizeWords,
  formatearFecha3,
  formatearMonedaSoles,
  formatDateTime,
  pagosPedido,
  registroPago
} from '../../../../Api/Api'

function ModalVerPagoPedido (datos) {
  //const [verPdf2, setVerPdf] = useState(datos.verpdf)
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rangoFechas, setrangoFechas] = useState(datos.rangoFechas)
  const [asistenciaDetalleData, setAsistenciaDetData] = useState(
    datos.asistenciaDetalleData
  )
  const [pagos, setPagos] = useState(null)
  const [noregistraPago, setNoRegistraPago] = useState(false)
  const [numeroPedido, setNumeroPedido] = useState('')
  const [totalPago, setTotalPago] = useState(0)
  const [codPedido, setCodPedido] = useState('')
  const [actualizoPagos,setActualizoPagos] = useState(false)

  useEffect(() => {
    const ejecucion = async () => {
      const respuesta = await pagosPedido(datos.codigoPedidoEliminar)
      if (respuesta) {
        console.log(respuesta)
        if (respuesta.respuesta.pagos.length > 0){
          setPagos(respuesta.respuesta.pagos)
          setNumeroPedido(respuesta.respuesta.pedido.codigoPedidoNumero)
          setTotalPago(respuesta.respuesta.pedido.monto_pagado)
          setCodPedido(respuesta.respuesta.pedido.codigoPedido)
        }
        else {
          setNoRegistraPago(prevState => !prevState)
          setPagos([])
        }
      }
    }
    ejecucion()
  }, [actualizoPagos])

  return (
    <>
      {console.log(datos.esComputadora)}
      <div id='baseModal' style={estilos.baseModal(datos.esComputadora)}>
        {datos.mostrarModal && (
          <div
            id='contenedorModal'
            style={estilos.contenedorModal(datos.esComputadora)}
          >
            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
              <Boton
                style={{
                  backgroundColor: '#E12121',
                  borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                  padding: datos.esComputadora ? '0.8vw' : '2vw'
                }}
                textoStyle={{
                  color: 'white',
                  width: '100%',
                  fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                }}
                texto='X'
                tipo='submit'
                tonalidad='20'
               
                estaProcesando={estaProcesando}
                onClick={datos.cerrarPdfModalClick}
                
              />
            </div>
            <div style={estilos.tituloCSS(datos.esComputadora)}>
              Registro de Pagos
            </div>
            <div
              id='contenidoModal'
              style={estilos.contenidoModal(datos.esComputadora)}
            >
              <table style={{...estilos.tablaCSS(datos.esComputadora),margin: "0px"}}>
                <thead>
                  <tr>
                    <th
                      colSpan='2'
                      style={{
                        ...estilos.thStyle(datos.esComputadora),
                        textAlign: 'right',
                        border: 'none'
                      }}
                    >
                      Nro. Pedido:
                    </th>
                    <td
                      style={{
                        ...estilos.thStyle(datos.esComputadora),
                        textAlign: 'left',
                        border: 'none'
                      }}
                    >
                      {String(numeroPedido).padStart(8, '0')}
                    </td>
                  </tr>
                  <tr>
                    <th
                      colSpan='2'
                      style={{
                        ...estilos.thStyle(datos.esComputadora),
                        textAlign: 'right',
                        border: 'none'
                      }}
                    >
                      Persona Solicitante:
                    </th>
                    <td
                      style={{
                        ...estilos.thStyle(datos.esComputadora),
                        textAlign: 'left',
                        border: 'none'
                      }}
                    >
                      {capitalizeWords(datos.nombreUsuarioPedido)}
                    </td>
                  </tr>
                </thead>
              </table>
              <table style={estilos.tablaCSS(datos.esComputadora)}>
                <thead>
                  <tr>
                    <th style={estilos.thStyle(datos.esComputadora)}>Monto</th>
                    <th style={estilos.thStyle(datos.esComputadora)}>
                      Fecha de Pago
                    </th>
                    <th style={estilos.thStyle(datos.esComputadora)}>
                      Fecha de Registro
                    </th>
                    <th style={estilos.thStyle(datos.esComputadora)}>
                      Opciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {console.log(pagos)}
                  {pagos !== null && pagos.length>0 &&
                    pagos.map(pago => {
                      //totalPago.current += pago.monto !== null && pago.monto
                      
                      //setTotalPago(prev => (prev+pago.monto))
                      return (
                        <tr key={pago.id} id={pago.id}>
                          <td style={estilos.tdStyle(datos.esComputadora)}>
                            {pago.monto !== null
                              ? formatearMonedaSoles(pago.monto)
                              : ''}
                          </td>
                          <td style={estilos.tdStyle(datos.esComputadora)}>
                            {pago.fecha_pago !== null
                              ? formatearFecha2(pago.fecha_pago)
                              : ''}
                          </td>
                          <td style={estilos.tdStyle(datos.esComputadora)}>
                            {pago.fecha_registro !== null
                              ? formatDateTime(pago.fecha_registro)
                              : ''}
                          </td>
                          <td style={estilos.tdStyle(datos.esComputadora)}>
                              <div
                                  style={estilos.contenedorBotonEditarStyle(
                                    datos.esComputadora
                                  )}
                                  onClick={async (event)=>{
                                    console.log(event.currentTarget.parentNode.parentNode.id)
                                    console.log(codPedido)
                                    const respueta = await registroPago(
                                      codPedido,
                                      '',
                                      '',
                                      'S',
                                      parseInt(event.currentTarget.parentNode.parentNode.id),
                                      5,
                                      ''
                                    )
                                    if(respueta){
                                      console.log(respueta)
                                      setActualizoPagos(prev=>!prev)
                                    }
                                  }}
                                >
                                  <button
                                    style={estilos.botonEditarStyle(
                                      datos.esComputadora
                                    )}
                                  >
                                    <img
                                      src={borrar}
                                      style={estilos.imagenBotonEditarStyle(
                                        datos.esComputadora
                                      )}
                                    />
                                  </button>
                                </div>
                          </td>
                        </tr>
                      )
                    })}
                  {pagos !== null && pagos.length>0 && (
                    <tr>
                      <th colSpan='2' style={{textAlign: "right"}}>Total Pagado:</th>
                      <th style={{textAlign: "left"}}>{formatearMonedaSoles(totalPago)}</th>
                    </tr>
                  )}
                  {noregistraPago ? (
                    <tr>
                      <td
                        colSpan='3'
                        style={{ borderBottom: '1px solid black' }}
                      >
                        No Registra Pagos
                      </td>
                    </tr>
                  ) : pagos === null ? (
                    <tr>
                      <td
                        colSpan='3'
                        style={{ borderBottom: '1px solid black' }}
                      >
                        Cargando Pagos . . .
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ModalVerPagoPedido
