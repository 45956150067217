import {
    hexToRGBA
} from '../../../../Api/Api'

import {StyleSheet } from '@react-pdf/renderer';

export const baseModal=(esComputadora)=>({
    position: "absolute",
    left: "0",
    top: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: hexToRGBA('#C1C1C1', 0.5),
    zIndex: "100"
})
export const botonCerrarModalStyle=(esComputadora)=>({
    display: "flex",
    gridArea: esComputadora ? ' 1 / 18 / 2 / 21':' 1 / 18 / 2 / 21',
    justifySelf: "right",
    alignItems: "center",
})

const widthModal=(esComputadora)=>{
    return esComputadora ? "25":"70"
}
const heightModal=(esComputadora)=>{
    return esComputadora ? "20":"15"
}
export const contenedorModal=(esComputadora)=>({
    borderRadius: "10px 10px 0px 0px",
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    gridTemplateRows: "repeat(2,1fr)",
    position: "absolute",
    left: ((100-widthModal(esComputadora))/2)+"vw",
    top: ((100-heightModal(esComputadora))/2)+"vh",
    width: widthModal(esComputadora)+"vw",
    backgroundColor: hexToRGBA("#ffffff",1),
    zIndex: "41", 
    boxSizing: "border-box"
  })
  export const tituloCSS=(esComputadora)=>({
    gridArea: esComputadora ? ' 1 / 2 / 1 / 4':' 1 / 2 / 1 / 4',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    //backgroundColor: "green",
    fontSize: esComputadora ? '1.2vw': '3.5vw'
})
export const contenidoModal=(esComputadora)=>({
    gridArea : "1/1/21/21",
    backgroundColor: "red",
    position: "absolute",
    top: "5vh",
    width: widthModal(esComputadora)+"vw",
    height: "auto",
    backgroundColor: hexToRGBA("#ffffff",1),
    zIndex: "42", 
    borderRadius: esComputadora ? "0px 0px 0.5vw 0.5vw":"0px 0px 1vw 1vw",
  })
export const formularioCSS= (esComputadora)=>({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(20,1fr)",
    griTemplateRows : "repeat(10,1fr)",
    marginTop: esComputadora ? "1vw":"2vw",
})

/*
export const estiloVacio = (esComputadora, envioForm, entrada) => ({
    border: !envioForm || entrada ? '1px solid #ccc' : '1px solid red',
    boxShadow:
      !envioForm || entrada ? 'inset 0 0 1px gray' : 'inset 0 0 5px red',
    
})*/

export const estiloVacio = (esComputadora, vacio) => ({
    //borderColor: usuarioVacio ? 'red':fg.hex_rgb("#ffffff",0),
    border:
      vacio || vacio === '' || vacio === 0
        ? `${esComputadora ? '0.1vw' : '0.2vw'} solid red`
        : `${esComputadora ? '0.1vw' : '0.2vw'} solid #ccc`
  })