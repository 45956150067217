import axios from 'axios'
import { MontoTotalDetalle } from '../Componentes/Principal/ContenidoCentral/Asistencias/AsistenciasCSS';

//const host = 'http://127.0.0.1:8000/';
//const host = 'http://192.168.1.43:8000/'
const host = 'https://api.industriasalimentariasvitopan.com/';

const establecerCookies = (clave, valor) => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1); // Agrega un mes
    const fechaExpiracion = date.toUTCString();
    document.cookie = `${clave}=${valor}; expires=${fechaExpiracion}; path=/;`;
  };

const eliminarCookies = () =>{
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
}

function actualizarCookie (nombre, nuevoValor) {
  var cookies = document.cookie.split(';')
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim()
    if (cookie.startsWith(nombre + '=')) {
      document.cookie = cookie.substring(0, nombre.length + 1) + nuevoValor
      break
    }
  }
}

export const obtenerCookie = nombre => {
  const cookies = document.cookie.split(';')
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim() // Elimina los espacios en blanco al principio y al final
    if (cookie.startsWith(nombre + '=')) {
      return cookie.substring(nombre.length + 1) // Retorna el valor de la cookie
    }
  }
  return null // Si no se encuentra la cookie, retorna null
}

const infoServ = () => {
  const usuario = obtenerCookie('usuario')
  const tokenSesion = obtenerCookie('token')
  return { usuario: usuario, tokenSesion: tokenSesion }
}

const ejecutarLlamado = async (apiNombre, request, options = {}) => {
  const { signal } = options;
  try {
    const respuesta = await axios.post(host + `${apiNombre}/`, request, { ...cabecera, signal });
    if (respuesta.data) {
      if (respuesta.data.VPListaErrores.length !== 0) {
        respuesta.data.VPListaErrores.map(i => { if (i.codError === 9998) { eliminarCookies() } });
      }
      return respuesta.data;
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('La solicitud fue abortada');
    } else {
      console.error('Error en la solicitud:', error);
    }
  }
}
function crearRequest(params) {
  return {
    VPServInfo: infoServ(),
    ...params
  };
}
/*
export const iniciarSesion = async (usuario, contrasenia) => {
    const opciones={
        method: 'POST',
        headers: {
          "Content-type": 'application/json',
        },
        body: JSON.stringify({
          "VPServInfo": infoServ(),
          "codigoUsuario": '',
          "contrasenia": '',
        }),
      };
    fetch(host, opciones).then((response) => response.json())
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error);
          return;
        }
      );
  } // Esta es la llave de cierre que faltaba
*/

const cabecera={
  headers: {
    'Content-type': 'application/json',
  },
};

export const validarSesion = async () => {
  const request = crearRequest({});
  return await ejecutarLlamado('validarSesion',request);
}

export const infoUsuario = async ()=>{
  const request = crearRequest({});
  return await ejecutarLlamado('obtenerInfoUsuario',request);
}

export const iniciarSesion = async (usuario, contrasenia) => {
  const request = crearRequest({
    codigoUsuario: usuario,
    contrasenia: contrasenia
  });
  const respuesta = await ejecutarLlamado('inicioSesion',request);
  
  if(respuesta) {
    if(respuesta.VPListaErrores.length === 0){
        establecerCookies("usuario",usuario);
        establecerCookies("token",respuesta.tokenSesion);
        const informacion = await infoUsuario()
        if (informacion.VPListaErrores.length ===0){
          establecerCookies("descRol",informacion.descRol)
          establecerCookies("codUsuario",informacion.codUsuario)
          establecerCookies("nombreUsuario",informacion.nombreUsuario)
          establecerCookies("apellidoMaterno",informacion.apellidoMaterno)
          establecerCookies("apellidoPaterno",informacion.apellidoPaterno)
        }
    }else{
      eliminarCookies()
    }
    return respuesta;
  }
  return respuesta;
}

export const cerrarSesion = async () =>{
  const request = crearRequest({});
  return await ejecutarLlamado('cerrarSesion',request);
}

export const listarPedidos = async (usuarioFiltro,fechaFiltro,ordenFecha) =>{
  const request = crearRequest({
    codUsuario: usuarioFiltro,
    fecha: fechaFiltro,// 1-fechaPedido,2-fechaEntregada,3-fechaEntregadaReal
    orden: ordenFecha// 1-descendente,2-ascendente
  });
  console.log(request)
  return await ejecutarLlamado('pedidos',request);
}

export const listaDistribuidores= async() =>{
  const request = crearRequest({});
  return await ejecutarLlamado('listarDistribuidores',request);
}

export const listarAyudantesDistribuidor = async(codDistribuidor) =>{
  const request = crearRequest({
    codUsuario:codDistribuidor
  });
  return await ejecutarLlamado('listarAyudantesDistribuidor',request);
}

export const ultimoPedido = async(codDistribuidor) =>{
  const request = crearRequest({
    codUsuario:codDistribuidor,
    tipoDistribucion: 2,
  });
  return await ejecutarLlamado('ultimoPedido',request);
}

export const actualizarPedido = async(codDistribuidor,codProductoIn,codPedidoIn,cantidadIn) =>{
  
  const request = crearRequest({
    "codUsuario":codDistribuidor,
    "codProducto": codProductoIn,
    "codPedido": codPedidoIn,
    "cantidad": cantidadIn,
    pedidooentrega: 1,
  });
  console.log(request)
  return await ejecutarLlamado('actualizacionPedido',request);
}

export const solicitarPedido = async(codDistribuidor,codPedidoIn,fechaRecojo,pedidooentregaIn) =>{
  const request = crearRequest({
    codUsuario:codDistribuidor,
    codPedido: codPedidoIn,
    pedidooentrega: pedidooentregaIn,
    fechaEntregaEstimada: fechaRecojo,
  });
  return await ejecutarLlamado('guardarPedido',request);
}

export const detallePedido = async(codPedidoIn) =>{
  const request = crearRequest({
    codPedido: codPedidoIn,
  });
  console.log(request)
  return await ejecutarLlamado('detalleEntrega',request);
}

export const actualizarEntrega = async(codDistribuidor,codProductoIn,codPedidoIn,cantidadIn) =>{
  const request = crearRequest({
    "codUsuario":codDistribuidor,
    "codProducto": codProductoIn,
    "codPedido": codPedidoIn,
    "cantidad": cantidadIn,
    pedidooentrega: 2,
  });
  return await ejecutarLlamado('actualizacionPedido',request);
}


export const entregar = async(codDistribuidor,codPedidoIn,fechaRecojo,pedidooentregaIn) =>{
  const request = crearRequest({
    codUsuario:codDistribuidor,
    codPedido: codPedidoIn,
    pedidooentrega: pedidooentregaIn,
    fechaEntregaEstimada: fechaRecojo,
  });
  console.log(request)
  return await ejecutarLlamado('guardarPedido',request);
}

export const listarPersonal = async() =>{
  const request = crearRequest({});
  return await ejecutarLlamado('personal',request);
}

export const pedidoTienda = async(codDistribuidor) =>{
  const request = crearRequest({
    codUsuario:codDistribuidor,
    tipoDistribucion: 1,
  });
  return await ejecutarLlamado('ultimoPedido',request);
}

export const obtenerProductos = async(codUsuarioIn) =>{
  const request = crearRequest({
    codUsuario:codUsuarioIn,
  });
  return await ejecutarLlamado('obtenerProductos',request);
}

export const actualizarStock = async(codProductoIn,cantidadIn,sa,ioa,) =>{
  
  const request = crearRequest({
    codProducto:codProductoIn,
    cantidad: cantidadIn,
    stockAnterior: sa,
    incrementoActualizacion: ioa
  });
  console.log(request)
  return await ejecutarLlamado('actualizarStock',request);
}

export const detallePedidoGenerico = async(codPedidoIn) =>{
  const request = crearRequest({
    codPedido:codPedidoIn,
  });
  return await ejecutarLlamado('detallePedido',request);
}

export const listarRoles = async() =>{
  const request = crearRequest({});
  return await ejecutarLlamado('listarRoles',request);
  
}

//
export const listarTiposDocumentos = async() =>{
  const request = crearRequest({});
  return await ejecutarLlamado('tipoDocumento',request);
}

export const listarDepartamentos = async(codPaisIn) =>{
  const request = crearRequest({
    codPais: codPaisIn
  });
  return await ejecutarLlamado('departamento',request);
  
}

export const listarProvincias = async(codPaisIn, codDepartamentoIn) =>{
  const request = crearRequest({
    codPais: codPaisIn,
    codDepartamento:codDepartamentoIn,
  });
  return await ejecutarLlamado('provincia',request);

}

export const listarDistritos = async(codPaisIn,codDepartamentoIn,codProvinciaIn) =>{
  const request = crearRequest({
    codPais: codPaisIn,
    codDepartamento: codDepartamentoIn,
    codProvincia: codProvinciaIn,
  });
  return await ejecutarLlamado('distrito',request);
}

export class Persona {
  constructor(numeroDocumento, nombres, apePaterno, apeMaterno, codDocumento, fecNac, ubigeo, direccion, correoElectronico, numCelular, arcDni, arcRec) {
    this.numeroDocumento = numeroDocumento;
    this.nombres = nombres;
    this.apePaterno = apePaterno;
    this.apeMaterno = apeMaterno;
    this.codDocumento = codDocumento;
    this.fecNac = fecNac;
    this.ubigeo = ubigeo;
    this.direccion = direccion;
    this.correoElectronico = correoElectronico;
    this.numCelular = numCelular;
    this.arcDni = arcDni;
    this.arcRec = arcRec;
  }
}
export const crearPersona = async(codEmpresaIn,codPlantaIn,Persona,codigoRolIn,codDistribuidorIn) =>{
  const request = crearRequest({
    codigoEmpresa: codEmpresaIn,
    codigoPlanta: codPlantaIn,
    codigoRol: codigoRolIn.toString(),
    codDistribuidor: codDistribuidorIn,
    VPListaPersonas: [JSON.parse(JSON.stringify(Persona))]
  });
  return await ejecutarLlamado('crearPersona',request);
}

export const guardarStock = async() =>{
  const request = crearRequest({});
  return await ejecutarLlamado('guardarStock',request);
}

export const listarProductos= async(codUsuarioIn) =>{
  const request = crearRequest({
                    codUsuario:codUsuarioIn,
                    todo: "S",
                  });
  return await ejecutarLlamado('obtenerProductos',request);
}

//MODIFICAR DESDE AQUI PARA ADMINISTRACION DE PRODUCTOS

//obtenerCategorias
export const obtenerCategorias = async (options = {}) => {
  const { signal } = options;
  const request = crearRequest({
    todo: "S"
  });
  return await ejecutarLlamado('obtenerCategorias', request, { signal });
}

export const obtenerDetalleProducto = async (codProducto, codUsuario, options = {}) => {
  const { signal } = options;
  const request = crearRequest({
    "codProducto": codProducto,
    "codUsuario": codUsuario
  });
  return await ejecutarLlamado('detalleProducto', request, { signal });
}
export const actualizarDetalleProductos = async (
  codUsuarioIn="",
  productosIn, 
  precioTiendaIn,
  precioClienteIn,
  precioDistribuidorIn,
  activoIn="N",
  editPTIn="N",
  editPCIn="N",
  editPDIn="N",
  editATIn="N",
  eliminarIn= "N"
)=>{
  
  const request = crearRequest({
    "codUsuario": codUsuarioIn,
    "productos": productosIn,//[1,2,3,4,5,6,7,8,1,2,3],
    "precioTienda": precioTiendaIn,
    "precioCliente": precioClienteIn,
    "precioDistribuidor": precioDistribuidorIn,
    "activo": activoIn,
    "editPT": editPTIn,
    "editPC": editPCIn,
    "editPD": editPDIn,
    "editAT": editATIn,
    "eliminar": eliminarIn
  });
  console.log(request)
  return await ejecutarLlamado('actualizarProductosGrupal',request);
}
export const eliminarProductos = async (
  codUsuarioIn="",
  productosIn,
  eliminarIn="S"
)=>{
  const request = crearRequest({
    "codUsuario": codUsuarioIn,
    "productos": productosIn,//[1,2,3,4,5,6,7,8,1,2,3],
    "eliminar": eliminarIn,

    "precioTienda": "",
    "precioCliente": "",
    "precioDistribuidor": "",
    "activo": "",
    "editPT": "",
    "editPC": "",
    "editPD": "",
    "editAT": "",
  });
  return await ejecutarLlamado('actualizarProductosGrupal',request);
}
export const registrarProducto = async (
  codProductoIn=0,
  codUsuarioIn,
  categoriaIn,
  descripcionIn,
  unidadesIn,
  precioTiendaIn,
  precioClienteIn,
  precioDistribuidorIn,
  activoIn="S",
)=>{
  
  const request = crearRequest({
    "codProducto":codProductoIn,//0->REGISTRAR PRODUCTO NUEVO, #(CUALQUIER OTRO)-> CODIGO DEL PRODUCTO A EDITAR
    "codUsuario":codUsuarioIn,
    "categoria": categoriaIn,
    "descripcion": descripcionIn,
    "unidades": unidadesIn,
    "precioTienda": precioTiendaIn,
    "precioCliente": precioClienteIn,
    "precioDistribuidor": precioDistribuidorIn,
    "activo":activoIn

  });
  console.log(request);
  return await ejecutarLlamado('registroProducto',request);
}
//FIN MODIFICACION PRODUCTOS


//ASISTENCIAS
export const asistencias= async()=>{
  const request = crearRequest({
  });
  return await ejecutarLlamado('reporteGlobalSemanal',request);
}

export const asistenciasDetalle= async(
  codReporteIn
)=>{
  const request = crearRequest({
    reporte: codReporteIn
  });
  return await ejecutarLlamado('detalleReporteGlobalSemanal',request);
}
//FIN ASISTENCIAS

export const eliminarPedido = async(codPedidoIn)=>{
  const request = crearRequest({
    pedido: codPedidoIn
  });
  return await ejecutarLlamado('eliminarPedido',request);
}

//INICIO PAGOS
export const registrarFormaPago = async(codUsuarioIn, pagoIn, forma_pagoIn, turnoIn)=>{
  const request = crearRequest({
    codUsuario  : codUsuarioIn,
    pago        : pagoIn,
    forma_pago  : forma_pagoIn,
    turno       : turnoIn,
  });
  return await ejecutarLlamado('informacionGeneral',request);
}

export const turnos = async() =>{
  const request = crearRequest({
  });
  return await ejecutarLlamado('listarTurno',request);
}
export const formasPago = async() =>{
  /*
  #F -> Fijo
    #H -> Horas
    #D -> Destajo
  */
  return {formasPago: [
    {codFormaPago: 'F', descripcion: 'Sueldo Fijo'},
    {codFormaPago: 'H', descripcion: 'Por Turno'},
    {codFormaPago: 'D', descripcion: 'Destajo'},
  ],
  VPListaErrores:[]  
}
}

export const registroPago = async(codPedidoIn, crearIn, editarIn, eliminarIn,pagoIn, montoIn, fechaIn) =>{
  const request = crearRequest({
    pedido    : codPedidoIn,
    crear     : crearIn,
    editar    : editarIn,
    eliminar  : eliminarIn,
    pago      : pagoIn,
    monto     : montoIn,
    fecha     : fechaIn
  });
  console.log(request)
  return await ejecutarLlamado('registroPago',request);
}
export const pagosPedido = async(codPedidoIn) =>{
  const request = crearRequest({
    pedido    : codPedidoIn
  });
  return await ejecutarLlamado('pagosPedido',request);
}
//FIN PAGOS

export const  hexToRGBA = (hex, opacidad) => {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}


export const  formatearMonedaSoles=(valor) =>{
  return valor.toLocaleString('es-PE', {
      style: 'currency',
      currency: 'PEN'
  });
}

export const capitalizeWords=(str) =>{
  let words = str.toLowerCase().split(' ');
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
}

export const  formatearFecha2 = (fechaApi)=> {
  // Asegurarse de que la fecha está en el formato correcto
  const partes = fechaApi.split('-');
  
  // Verificar que se hayan obtenido las 3 partes
  if (partes.length !== 3) {
      throw new Error('Formato de fecha incorrecto. Debe ser yyyy-mm-dd.');
  }
  
  const anio = partes[0];
  const mes = partes[1];
  const dia = partes[2];

  // Retornar la fecha en el nuevo formato
  return `${dia}/${mes}/${anio}`;
}

export const formatDateTime=(dateTimeStr) =>{
  // Expresión regular para extraer partes de la fecha y hora UTC
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.\d{3})?Z$/;
  // Intentar hacer coincidir la cadena de fecha y hora con la expresión regular
  const match = dateTimeStr.match(regex);
  if (!match) {
    // Manejo de error si el formato de entrada no es válido
    console.error('Formato de fecha y hora no válido:', dateTimeStr);
    return null;
  }

  // Extraer partes de la fecha y hora UTC
  const [, year, month, day, hour, minute] = match;

  // Convertir la hora de formato de 24 horas a formato de 12 horas con AM/PM
  let formattedHour = parseInt(hour, 10);
  const ampm = formattedHour >= 12 ? 'pm' : 'am';
  formattedHour = formattedHour % 12;
  formattedHour = formattedHour ? formattedHour : 12; // Hora '0' debería ser '12'

  // Formatear la hora en formato de 12 horas con AM/PM
  const formattedTime = `${formattedHour}:${minute} ${ampm}`;

  // Formatear la fecha en dd/mm/yyyy
  const formattedDate = `${day}/${month}/${year}`;

  // Salida final en el formato deseado
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
}

export const formatearFecha3=(dateTimeStr) =>{
  // Expresión regular para extraer partes de la fecha y hora UTC
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.\d{3})?Z$/;
  // Intentar hacer coincidir la cadena de fecha y hora con la expresión regular
  const match = dateTimeStr.match(regex);
  if (!match) {
    // Manejo de error si el formato de entrada no es válido
    console.error('Formato de fecha y hora no válido:', dateTimeStr);
    return null;
  }

  // Extraer partes de la fecha y hora UTC
  const [, year, month, day, hour, minute] = match;

  // Convertir la hora de formato de 24 horas a formato de 12 horas con AM/PM
  let formattedHour = parseInt(hour, 10);
  const ampm = formattedHour >= 12 ? 'pm' : 'am';
  formattedHour = formattedHour % 12;
  formattedHour = formattedHour ? formattedHour : 12; // Hora '0' debería ser '12'

  // Formatear la hora en formato de 12 horas con AM/PM
  const formattedTime = `${formattedHour}:${minute} ${ampm}`;

  // Formatear la fecha en dd/mm/yyyy
  const formattedDate = `${day}/${month}/${year}`;

  // Salida final en el formato deseado
  const formattedDateTime = `${formattedTime}`;

  return formattedDateTime;
}