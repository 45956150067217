import React, { useState, useEffect } from 'react'
import './InicioSesion.css'
import Input from './../../Elementos/Input'
import Boton from './../../Elementos/Boton'
import LogoVitopan from './../../Imagenes/LogoVitopan.png'
import { iniciarSesion, validarSesion } from '../../Api/Api'
import { useNavigate } from 'react-router-dom'
import * as e from './InicioSesionCSS'
import * as fg from './../../Api/FuncionesGlobales'

function InicioSesion (param) {
  const [esComputadora, setesComputadora] = useState(
    window.innerWidth > window.innerHeight
  )
  const [estaProcesando, setestaProcesando] = useState(false)
  const [usuario, setUsuario] = useState('')
  const [contrasenia, setContrasenia] = useState('')
  const [usuarioVacio, setUsuarioVacio] = useState(false)
  const [contraseniaVacio, setContraseniaVacio] = useState(false)
  const [errores, seterrores] = useState(null)
  const [hayError, setHayError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const validar = async () => {
      const respuesta = await validarSesion() // Esta función debe retornar el resultado que necesitas para la validación
      if (
        respuesta.VPListaErrores.length === 0 &&
        respuesta.estado === 'activo'
      ) {
        navigate('/principal')
      }
    }
    validar()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setesComputadora(window.innerWidth > window.innerHeight)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const cambioUsuario = event => {
    setUsuario(event.target.value)
    setUsuarioVacio(false)
  }

  const cambioContrasenia = event => {
    setContrasenia(event.target.value)
    setContraseniaVacio(false)
  }
  const ingresarBotonClick = async event => {
    event.preventDefault()
    if (usuario === '') {
      setUsuarioVacio(true)
      setHayError(true)
      seterrores({
        VPListaErrores: [
          { codError: 1, descripcionError: '⚠️No puede haber campos vacíos.' }
        ]
      })
    }
    if (contrasenia === '') {
      setContraseniaVacio(true)
      setHayError(true)
      seterrores({
        VPListaErrores: [
          { codError: 1, descripcionError: '⚠️No puede haber campos vacíos.' }
        ]
      })
    }
    if (usuario !== '' && contrasenia !== '') {
      setestaProcesando(true)
      setHayError(false)
      const respuesta = await iniciarSesion(usuario.trim(), contrasenia.trim())
      setestaProcesando(false)

      if (respuesta.VPListaErrores.length !== 0) {
        setHayError(true)
        seterrores(respuesta)
      } else {
        setHayError(false)
        navigate('/principal')
      }
    }
  }
  return (
    <div
      id='dispositivoContenedor'
      style={{ height: '100vh', position: 'relative' }}
    >
      <div
        id='contenedor'
        style={{
          ...e.estiloContenedor(esComputadora),
          flexDirection: esComputadora ? 'row' : 'column-reverse'
        }}
      >
        <div
          id='contenedorLogin'
          style={e.estiloContenedorLogin(esComputadora)}
        >
          <div style={e.estiloCabecera(esComputadora)}>Iniciar Sesión</div>
          <form style={{padding: "0",margin: "0", width: '100%' }}>
            
            <Input
              style={{
                marginBottom:"1vw",
                ...(usuarioVacio
                  ? e.estiloVacio(esComputadora, usuarioVacio)
                  : {})
              }}
              textoStyle={{}}
              id='codigoUsuario'
              moderno='Código de Usuario'
              tipo='text'
              onChange={cambioUsuario}
              esComputadora={esComputadora}
              valorInicial={''}
            />
            <Input
              style={
                
                contraseniaVacio
                  ? e.estiloVacio(esComputadora, contraseniaVacio)
                  : {}
              }
              textoStyle={{
                
              }}
              id='contrasenia'
              moderno='Contraseña'
              tipo='password'
              autoComplete
              onChange={cambioContrasenia}
              esComputadora={esComputadora}
              valorInicial={''}
            />

            <Boton
              style={{
                backgroundColor: '#FFC300AA',
                borderRadius: esComputadora ? '0.5vw':'1vw',
                padding: esComputadora ? '0.8vw':'2vw',
                marginTop: "1vw",
              }}
              textoStyle={{
                color: 'BLACK',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Ingresar'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={ingresarBotonClick}
            />
            {hayError
              ? errores.VPListaErrores.map(i => {
                  return (
                    <p style={e.estilosError(esComputadora)} key={i.codError}>
                      {i.descripcionError}
                    </p>
                  )
                })
              : ''}
          </form>
        </div>
        <div id='contenedorLogo' style={e.estiloContenedorLogo(esComputadora)}>
          <img
            src={LogoVitopan}
            alt='Logo'
            style={{ width: esComputadora ? '100%' : '70%', height: 'auto' }}
          />
          <div id='eslogan' style={e.estiloEslogan(esComputadora)}>
            ¡Cómo hecho en casa!
          </div>
        </div>
      </div>
    </div>
  )
}

export default InicioSesion
