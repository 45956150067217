import {
    hexToRGBA
} from '../../../../Api/Api'

import {StyleSheet } from '@react-pdf/renderer';

export const baseModal=(esComputadora)=>({
    position: "absolute",
    left: "0",
    top: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: hexToRGBA('#C1C1C1', 0.5),
    zIndex: "100"
})
export const botonCerrarModalStyle=(esComputadora)=>({
    display: "flex",
    gridArea: esComputadora ? ' 1 / 18 / 2 / 21':' 1 / 18 / 2 / 21',
    justifySelf: "right",
    alignItems: "center",
})

const widthModal=(esComputadora)=>{
    return esComputadora ? "30":"70"
}
const heightModal=(esComputadora)=>{
    return esComputadora ? "20":"20"
}
export const contenedorModal=(esComputadora)=>({
    borderRadius: "10px 10px 0px 0px",
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    gridTemplateRows: "repeat(2,1fr)",
    position: "absolute",
    left: ((100-widthModal(esComputadora))/2)+"vw",
    top: ((100-heightModal(esComputadora))/2)+"vh",
    width: widthModal(esComputadora)+"vw",
    backgroundColor: hexToRGBA("#ffffff",1),
    zIndex: "41", 
    boxSizing: "border-box"
  })
export const contenidoModal=(esComputadora)=>({
    gridArea : "1/1/21/21",
    backgroundColor: "red",
    position: "absolute",
    top: "5vh",
    width: widthModal(esComputadora)+"vw",
    height: "auto",
    backgroundColor: hexToRGBA("#ffffff",1),
    zIndex: "42", 
    borderRadius: "0px 0px 10px 10px",
    padding: "10px",
    boxSizing: "border-box"
  })

export const tituloCSS=(esComputadora)=>({
    gridArea: esComputadora ? ' 1 / 2 / 1 / 4':' 1 / 2 / 1 / 4',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    //backgroundColor: "green",
    fontSize: esComputadora ? '1.2vw': '3.5vw'
})

export const tablaCSS=(esComputadora)=>({
    width: "100%",
    borderCollapse: "separate", /* Cambiar a 'separate' para aplicar border-spacing */
    borderSpacing: "10px 1px", /* Espacio entre celdas */
    margin: "20px 0px",
    fontSize: esComputadora ? "1vw": "2.5vw"
})
export const thStyle=(esComputadora)=>({
    borderBottom: "1px solid black",
    //padding: "8px",
    textAlign: "left",
})

export const tdStyle=(esComputadora)=>({
    borderBottom: "1px solid black",
    paddingRight: "8px",
    textAlign: "left",
})

export const contenedorBotonEditarStyle = (esComputadora)=>({
    gridArea: "1/8/2/9",
    marginRight: esComputadora ? "": "10px",
    display: "flex",
    justifyContent: "center", alignItems: "center",
    
  });

  export const botonEditarStyle= (esComputadora) => ({
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    filter: "drop-shadow(0.5px 1px 2px rgba(1, 1, 1, 0.5))",
  
  });

  export const imagenBotonEditarStyle= (esComputadora) => ({
    width: esComputadora ? '1.5vw':'2.3vh', 
    height: 'auto'
    
  });