import React, { useState, useEffect } from 'react'
import despacho from './../../../Imagenes/Iconos/despacho.png'
import generarPedido from './../../../Imagenes/Iconos/generarPedido.png'
import informacionGeneral from './../../../Imagenes/Iconos/informacionGeneral.png'
import pedidos from './../../../Imagenes/Iconos/pedidos.png'
import stock from './../../../Imagenes/Iconos/stock.png'
import ventaTienda from './../../../Imagenes/Iconos/ventaTienda.png'
import menuOpciones from './../../../Imagenes/Iconos/menuOpciones.png'
import asistencia from './../../../Imagenes/Iconos/asistencia.png'

import { obtenerCookie } from '../../../Api/Api'
import Boton from './../../../Elementos/Boton'
import './MenuOpciones.css'

function MenuOpciones (param) {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [esComputadora, setesComputadora] = useState(
    window.innerWidth > window.innerHeight
  )
  const [isHovered, setIsHovered] = useState(false)
  const [idHovered, setIdHovered] = useState('')
  const [diferenciaDimensiones, setDifDim] = useState(0.0)
  const [idSeleccionado, setIdSeleccionado] = useState(null)
  const [rol, setRol] = useState(
    obtenerCookie('descRol') !== null
      ? obtenerCookie('descRol').toUpperCase().trim()
      : ''
  )
  const [subMenuHabilitar, setSubMenuHabilitar] = useState(false)
  const [idSubMenuHabilitar, setIdSubMenuHabilitar] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setesComputadora(window.innerWidth > window.innerHeight)
      if (window.innerWidth > window.innerHeight) {
        setDifDim(window.innerWidth - window.innerHeight)
      } else {
        setDifDim(window.innerHeight - window.innerWidth)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function hexToRGBA (hex, opacidad) {
    hex = hex.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacidad})`
  }
  const contenedorOpcionesStyle = {
    display: 'grid',
    gridTemplateRows: esComputadora ? 'repeat(5, 1fr)' : '40px 100%',
    gridTemplateColumns: esComputadora
      ? 'repeat(1, 1fr) 160px'
      : 'repeat(10, 1fr)',
    width: esComputadora ? '5vw' : '100vw',
    height: esComputadora ? '' : '10vh',
    boxSizing: 'content-box',
    alignSelf: 'center',
    justifySelf: 'center',
    backgroundColor: "#6E0C0C"
  }
  const propiedadesAdicionalesIconos = {
    backgroundColor: hexToRGBA('#6E0C0C', 1.0),
    width: esComputadora ? '5vw' : '100%',
    height: esComputadora ? '100vh' : '10vh',
    textAlign: 'center',
    boxSizing: 'border-box',
    paddingTop: '2vh',
    margin: '0px',
    height: esComputadora ? '15vh' : '10vh'
  }
  const estiloHover = {
    ...(isHovered && {
      cursor: 'pointer',
      backgroundColor: hexToRGBA('#9E5400', 1.0),
      boxShadow: 'inset 0 0 10px #FFFFFF',
      color: "black",
      ...(!esComputadora && { borderBottom: '5px solid #603711' }),
      ...(esComputadora && { borderLeft: '5px solid #603711' })
    })
  }
  const estiloSeleccionado = {
    cursor: 'pointer',
    backgroundColor: hexToRGBA('#DCAD2F', 1.0),
    boxShadow: 'inset 0 0 22px #FFFFFF',
    ...(!esComputadora && { borderBottom: '5px solid #603711' }),
    ...(esComputadora && { borderLeft: '5px solid #603711' }),
    color: "black",
    
  }
  const botoMenuContextostyle = {
    backgroundColor: 'red',
    gridArea: esComputadora ? '1 / 1 / 4 / 2' : '1 / 1 / 2 / 3',
    ...propiedadesAdicionalesIconos
  }

  const pedidosstyle = {
    color: "#cfcfcf",
    display: rol === 'JEFE DE EMPAQUETADO' ? 'none' : 'inline-block',
    gridArea: esComputadora
      ? rol === 'ADMINISTRADOR'
        ? '4 / 1 / 5 / 2'
        : '2 / 1 / 4 / 2'
      : rol === 'CLIENTE' ||
        rol === 'AYUDANTE DE DISTRIBUIDOR'
      ? '1 / 6 / 2 / 8'
      : rol === 'SECRETARIO'
      ? '1 / 1 / 2 / 3'
      : '1 / 5 / 2 / 7',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'despachoDePedidos' ? estiloHover : ''),
    ...(idSeleccionado === 'despachoDePedidos' ? estiloSeleccionado : '')
  }
  const generarPedidostyle = {
    color: "#cfcfcf",
    display:
      rol === 'CLIENTE' || rol === 'AYUDANTE DE DISTRIBUIDOR'
        ? 'inline-block'
        : 'none',
    gridArea: esComputadora ? '3 / 1 / 4 / 2' : '1 / 4 / 2 / 6',
    height: esComputadora ? '' : '10vh',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'generarPedido' ? estiloHover : ''),
    ...(idSeleccionado === 'generarPedido' ? estiloSeleccionado : '')
  }
  const despachostyle = {
    display:
      rol === 'CLIENTE' ||
      rol === 'AYUDANTE DE DISTRIBUIDOR' ||
      rol === 'JEFE DE EMPAQUETADO'
        ? 'none'
        : 'inline-block',
    gridArea: esComputadora
      ? rol === 'ADMINISTRADOR'
        ? '5 / 1 / 6 / 2'
        : '4 / 1 / 5 / 2'
      : rol === 'ADMINISTRADOR'
      ? '1 / 7 / 2 / 9'
      : '1 / 5 / 2 / 7',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'registroDeVentas' ? estiloHover : ''),
    ...(idSeleccionado === 'registroDeVentas' ? estiloSeleccionado : '')
  }
  const ventaTiendastyle = {
    display:
      rol === 'CLIENTE' ||
      rol === 'AYUDANTE DE DISTRIBUIDOR' ||
      rol === 'ADMINISTRADOR' ||
      rol === 'JEFE DE EMPAQUETADO'
        ? 'none'
        : 'inline-block',
    gridArea: esComputadora ? '5 / 1 / 6 / 2' : '1 / 7 / 2 / 9',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'ventaTienda' ? estiloHover : ''),
    ...(idSeleccionado === 'ventaTienda' ? estiloSeleccionado : '')
  }
  const stockstyle = {
    color: "#cfcfcf",
    display:
      rol === 'CLIENTE' || rol === 'AYUDANTE DE DISTRIBUIDOR'
        ? 'none'
        : 'inline-block',
    gridArea: esComputadora ? '6 / 1 / 7 / 2' : '1 / 9 / 2 / 11',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'stockProductos' ? estiloHover : ''),
    ...(idSeleccionado === 'stockProductos' ? estiloSeleccionado : '')
  }
  const iconostyle = {
    width: esComputadora ? '60%' : '30%',
    filter : 'invert(50%) brightness(2) drop-shadow(1px 1px 0.1px rgba(1, 1, 1, 1))'
  }
  const descripcionIconoStyle = {
    fontWeight: 'bolder',
    fontSize: esComputadora ? '0.8vw' : '2vw',
    margin: '0px',
    padding: '0px',
    lineHeight: '0.9',
    
    
  }
  const personal = {
    width: '20%'
  }
  const informacionGeneralstyle = {
    color: "#cfcfcf",
    display:
      (obtenerCookie('descRol') !== null
        ? obtenerCookie('descRol').toUpperCase().trim()
        : '') === 'ADMINISTRADOR'
        ? 'inline-block'
        : 'none',
    gridArea: esComputadora ? '2 / 1 / 4 / 2' : '1 / 1 / 2 / 3',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'informacionGeneral' ? estiloHover : ''),
    ...(idSeleccionado === 'informacionGeneral' ? estiloSeleccionado : '')
  }
  const generarPedidoDistribuidorstyle = {
    color: "#cfcfcf",
    display:
      (obtenerCookie('descRol') !== null
        ? obtenerCookie('descRol').toUpperCase().trim()
        : '') === 'SECRETARIO' ||
      (obtenerCookie('descRol') !== null
        ? obtenerCookie('descRol').toUpperCase().trim()
        : '') === 'ADMINISTRADOR'
        ? 'inline-block'
        : 'none',
    gridArea: esComputadora ? '3 / 1 / 4 / 2' : '1 / 3 / 2 / 5',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'generarPedidoDistribuidor' || idHovered === 'asistencias'
      ? estiloHover
      : ''),
    ...(idSeleccionado === 'generarPedidoDistribuidor' ||
    idSeleccionado === 'asistencias'
      ? estiloSeleccionado
      : '')
  }
  const manejadorOpcionClick = async event => {
    if (event.currentTarget.id !== 'informacionGeneral') {
      param.establecerOpcion(event.currentTarget.id)
      setIdHovered(event.currentTarget.id)
      setIsHovered(true)
      setIdSeleccionado(event.currentTarget.id)
      setSubMenuHabilitar(false)
    } else {
      if (subMenuHabilitar) {
        setSubMenuHabilitar(false)
      } else {
        setSubMenuHabilitar(true)
        setIdSubMenuHabilitar(event.currentTarget.id)
      }
    }
  }
  const subopInfGenStyle = {
    display: subMenuHabilitar ? '' : 'none',
    gridArea: esComputadora ? '2/2/7/3' : '1/1/2/4',
    zIndex: '10',
    backgroundColor: '#F1EDE3',
    position: esComputadora ? '' : 'relative',
    height: esComputadora ? '' : '20.5vh',
    top: esComputadora ? '' : '-20.5vh',
    left: esComputadora ? '' : '2px',
    padding: esComputadora ? '1vw' : '2vw',
    boxSizing: 'border-box',
    borderRadius: esComputadora ? '0 1vw 1vw 0' : '2vw 2vw 0 0',
    boxShadow: `inset 0 0 8px ${hexToRGBA('#000000', 0.75)}`,
    width: esComputadora ? '10vw' : ''
  }

  const crearPersonaClick = event => {
    console.log(event.currentTarget.id)
    param.establecerOpcion(event.currentTarget.id)
    setIdSeleccionado(idSubMenuHabilitar)
    setSubMenuHabilitar(false)
  }
  const productosClick = event => {
    console.log(event.currentTarget.id)
    param.establecerOpcion(event.currentTarget.id)
    setIdSeleccionado(idSubMenuHabilitar)
    setSubMenuHabilitar(false)
  }
  return (
    <>
      <div id='contenedorOpciones' style={contenedorOpcionesStyle}>
        {esComputadora && (
          <div id='botoMenuContexto' style={botoMenuContextostyle}>
            <img src={menuOpciones} alt='Logo' style={personal} />
          </div>
        )}
        <div
          id='despachoDePedidos'
          style={pedidosstyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img
            src={
              rol === 'CLIENTE' ||
              rol === 'AYUDANTE DE DISTRIBUIDOR' ||
              rol === 'ADMINISTRADOR'
                ? pedidos
                : despacho
            }
            alt='Logo'
            style={
              {
                ...iconostyle,
                ...(idSeleccionado === "despachoDePedidos" || idHovered==="despachoDePedidos" ? {
                  filter: ""
                }: "")
              }
            }
          />
          <p style={descripcionIconoStyle}>
            {rol === 'CLIENTE' ||
            rol === 'AYUDANTE DE DISTRIBUIDOR' ||
            rol === 'ADMINISTRADOR'
              ? 'Lista de Pedidos'
              : 'Despacho de Pedidos'}
          </p>
        </div>
        <div
          id='generarPedido'
          style={generarPedidostyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={generarPedido} alt='Logo' style={
              {
                ...iconostyle,
                ...(idSeleccionado === "generarPedido" || idHovered==="generarPedido" ? {
                  filter: ""
                }: "")
              }
            } />
          <p style={descripcionIconoStyle}>Generar Pedido</p>
        </div>
        {/* {<div
          id='registroDeVentas'
          style={despachostyle}
          onMouseEnter={event => { 
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={pedidos} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>Registro de Ventas</p>
        </div> */}
        
        {/* <div
          id='ventaTienda'
          style={ventaTiendastyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={ventaTienda} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>Venta en Tienda</p>
        </div> */}
        <div
          id='stockProductos'
          style={stockstyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={stock} alt='Logo' style={
              {
                ...iconostyle,
                ...(idSeleccionado === "stockProductos" || idHovered==="stockProductos" ? {
                  filter: ""
                }: "")
              }
            } />
          <p style={descripcionIconoStyle}>Stock de Productos</p>
        </div>

        <div
          id='informacionGeneral'
          style={informacionGeneralstyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={informacionGeneral} alt='Logo' style={
              {
                ...iconostyle,
                ...(idSeleccionado === "informacionGeneral" || idHovered==="informacionGeneral" ? {
                  filter: ""
                }: "")
              }
            }/>
          <p style={
            descripcionIconoStyle
            }>Información General</p>
        </div>
        
        {rol === 'SECRETARIO' && <div
          id={
            rol === 'ADMINISTRADOR'
              ? 'asistencias'
              : 'generarPedidoDistribuidor'
          }
          style={generarPedidoDistribuidorstyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img
            src={rol === 'ADMINISTRADOR' ? asistencia : generarPedido}
            alt='Logo'
            style={
              {
                ...iconostyle,
                ...(idSeleccionado === "generarPedidoDistribuidor" || idHovered==="generarPedidoDistribuidor" ? {
                  filter: ""
                }: "")
              }
            }
          />
          
           <p style={descripcionIconoStyle}>
            {rol === 'ADMINISTRADOR'
              ? 'Asistencias'
              : 'Generar Pedido Distribuidor'}
          </p>
        </div>
        }
        <div id='subopInfGen' style={subopInfGenStyle}>
          <div>
            <div style={
              {fontSize: param.esComputadora ? '0.8vw' : '2.3vw',
                fontWeight: 'bolder',
              }
            }>Administración de Personal</div>
            <Boton
              style={{
                backgroundColor: '#FFFFFF',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw',
                border: "0.05vw solid black"
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw',
                color: 'black',
                fontWeight: 'bolder',
                fontSize: esComputadora ? '0.7vw' : '2vw',
                margin: '0px',
                padding: '0px',
                lineHeight: '0.9'
              }}
              texto='Personal'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={crearPersonaClick}
              id='crearPersona'
            />
            <div style={
              {fontSize: param.esComputadora ? '0.8vw' : '2.3vw',
                fontWeight: 'bolder',
              }
            }>Administración de Productos</div>

            <Boton
              style={{
                backgroundColor: '#ffffff',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw',
                border: "0.05vw solid black"
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw',
                color: 'black',
                fontWeight: 'bolder',
                fontSize: esComputadora ? '0.7vw' : '2vw',
                lineHeight: '0.9'
              }}
              texto='Productos'
              tipo='submit'
              tonalidad='20'
             
              estaProcesando={estaProcesando}
              onClick={productosClick}
             
              id='administrarProductos'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MenuOpciones
