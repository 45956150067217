import React, { useState, useEffect } from 'react'
import {
  listaDistribuidores,
  listarAyudantesDistribuidor,
  listarPedidos,
  obtenerCookie
} from './../../../../Api/Api'
import Opciones from './../../../../Elementos/Opciones'
import DetallePedido from './DetallePedido'
import Boton from './../../../../Elementos/Boton'
import borrar from './../../../../Imagenes/Iconos/borrar.png'
import ModalPedidos from './ModalPedidos'
import ModalVerPagoPedido from './ModalVerPagoPedido'
import ModalGenerarPagoPedido from './ModalGenerarPagoPedido'

import * as fg from './../../../../Api/FuncionesGlobales'
import * as e from './ListaPedidosCSS'

function ListarPedidos (datos) {
  const [distribuidores, setDistribuidores] = useState(null)
  const [distribuidorSeleccionado, setDistribuidorSeleccionado] = useState('')
  const [ayudantes, setAyudantes] = useState(null)
  const [ayuDistSelec, setayuDistSelec] = useState('')
  const [pedidos, setPedidos] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [idHovered, setIdHovered] = useState('')
  const [codPedidos, setCodPedidos] = useState({})
  const [idSeleccionado, setIdSeleccionado] = useState(null)
  const [distSeleccionadoModal, setdistSeleccionadoModal] = useState(null)
  const [coddistSeleccionadoModal, setcoddistSeleccionadoModal] = useState(null)
  const [fecSolSeleccionadoModal, setfecSolSeleccionadoModal] = useState(null)
  const [modalDetalle, setModalDetalle] = useState(false)
  const [rol, setRol] = useState(
    obtenerCookie('descRol') !== null
      ? obtenerCookie('descRol').toUpperCase().trim()
      : ''
  )
  const [codUsuarioSesion, setcodUsuarioSesion] = useState(
    obtenerCookie('codUsuario') !== null
      ? obtenerCookie('codUsuario').toUpperCase().trim()
      : ''
  )
  const [eliminar, setEliminar] = useState(false)
  const [codigoPedidoEliminar, setCodigoPedidoEliminar] = useState(0)
  const [nombreUsuarioPedido, setNombreUsuarioPedido] = useState('')
  const [deudaTotal, setDeudaTotal] = useState(0)
  const [modalPago, setModalPago] = useState(false)
  const [modalGenerarPago, setModalGenerarPago] = useState(false)
  const [celDist, setCelDist] = useState(null)
  const [nroPedido, setNroPedido] = useState(0)

  useEffect(() => {
    const listaDistribuidoresData = async () => {
      if (rol === 'CLIENTE') {
        const respuesta = await listarAyudantesDistribuidor(codUsuarioSesion)
        if (respuesta.VPListaErrores.length === 0) {
          setAyudantes(respuesta.ayudanteDistribuidores)
        } else {
          setAyudantes(null)
        }
      } else {
        const respuesta = await listaDistribuidores() // Esta función debe retornar el resultado que necesitas para la validación
        const respuesta2 = await listarPedidos(ayuDistSelec ==="" ? distribuidorSeleccionado===""?'':distribuidorSeleccionado:ayuDistSelec, 3, 1)
        if (respuesta) {
          if (respuesta.VPListaErrores.length === 0) {
            setDistribuidores(respuesta.Distribuidores)
            respuesta.Distribuidores.map(i => {
              setCelDist(prev => ({...prev, [i.codUsuario]:i.Celular}))
            })
          }
        }
        if (respuesta2) {
          if (respuesta2.VPListaErrores.length === 0) {
            setPedidos(respuesta2.Pedidos)
            setDeudaTotal(respuesta2.Deuda)
          }
        }
      }
    }
    listaDistribuidoresData()
  }, [eliminar,modalGenerarPago])

  useEffect(() => {
    const listaDistribuidoresData = async () => {
      const respuesta = await listaDistribuidores() // Esta función debe retornar el resultado que necesitas para la validación
      const respuesta2 = await listarPedidos(ayuDistSelec ==="" ? distribuidorSeleccionado===""?'':distribuidorSeleccionado:ayuDistSelec, 3, 1)
      if (respuesta.VPListaErrores.length === 0) {
        setDistribuidores(respuesta.Distribuidores)
      }
      if (respuesta2.VPListaErrores.length === 0) {
        setPedidos(respuesta2.Pedidos)
      }
    }
    listaDistribuidoresData()
  }, [modalDetalle,modalGenerarPago])

  useEffect(() => {
    if (pedidos !== null) {
      pedidos.forEach(pedido => {
        setCodPedidos(prevState => ({
          ...prevState,
          [pedido.codPedido]: false
        }))
      })
    }
  }, [pedidos,modalGenerarPago])

  const distribuidorSeleccionar = async valor => {
    setDistribuidorSeleccionado(valor)
  }

  useEffect(() => {
    if(rol !== "CLIENTE"){

    
      if (distribuidorSeleccionado !== null) {
        async function fetchData () {
          const respuesta = await listarAyudantesDistribuidor(
            distribuidorSeleccionado
          )
          const respuesta2 = await listarPedidos(distribuidorSeleccionado, 3, 1)
          if (respuesta.VPListaErrores.length === 0) {
            setAyudantes(respuesta.ayudanteDistribuidores)
          } else {
            setAyudantes(null)
          }
          if (respuesta2.VPListaErrores.length === 0) {
            setPedidos(respuesta2.Pedidos)
            setDeudaTotal(respuesta2.Deuda)
          }
        }
        fetchData()
      }
    }
  }, [distribuidorSeleccionado])

  const ayudanteDistribuidor = valor => {
    setayuDistSelec(valor)
  }

  useEffect(() => {
    if (ayuDistSelec !== null) {
      async function fetchData () {
        const respuesta = await listarPedidos(ayuDistSelec === '' ? distribuidorSeleccionado:ayuDistSelec, 3, 1)
        if (respuesta) {
          if (respuesta.VPListaErrores.length === 0) {
            setPedidos(respuesta.Pedidos)
            setDeudaTotal(respuesta.Deuda)
            //setAyudantes(respuesta.ayudanteDistribuidores);
          } else {
            //setAyudantes(null)
          }
        }
      }
      fetchData()
    }
  }, [ayuDistSelec])

  

  const manejadorOpcionClick = async event => {
    setIdSeleccionado(event.currentTarget.id)
    setdistSeleccionadoModal(
      fg.capitalizar_palabra(event.currentTarget.getAttribute('nombre'))
    )
    setcoddistSeleccionadoModal(
      event.currentTarget.getAttribute('codigoDistribuidor')
    )
    setfecSolSeleccionadoModal(
      event.currentTarget.getAttribute('fechasolicitada')
    )
    setModalDetalle(true)
  }
  const cerrarModalClick = () => {
    setModalDetalle(false)
  }
  
  
  return (
    <>
      {eliminar && (
        <ModalPedidos
          esComputadora={datos.esComputadora}
          cerrarPdfModalClick={() => {
            setEliminar(false)
          }}
          mostrarModal={true}
          codigoPedidoEliminar={codigoPedidoEliminar}
          nroPedidoEliminar={nroPedido}
        />
      )}
      {modalPago && (
        <ModalVerPagoPedido
          esComputadora={datos.esComputadora}
          cerrarPdfModalClick={() => {
            setModalPago(prevState => !prevState)
          }}
          mostrarModal={true}
          codigoPedidoEliminar={codigoPedidoEliminar}
          nombreUsuarioPedido={nombreUsuarioPedido}
        />
      )}
      {modalGenerarPago && (
        <ModalGenerarPagoPedido
          esComputadora={datos.esComputadora}
          cerrarPdfModalClick={() => {
            setModalGenerarPago(prevState => !prevState)
          }}
          mostrarModal={true}
          codigoPedidoEliminar={codigoPedidoEliminar}
          nombreUsuarioPedido={nombreUsuarioPedido}
        />
      )}
      {modalDetalle ? (
        <div id='modalStyle' style={e.modalStyle(datos.esComputadora)}>
          <div id='contContenidoModalStyle' style={e.contContenidoModalStyle(datos.esComputadora)}>
            <div id='contenidoModalStyle' style={e.contenidoModalStyle(datos.esComputadora)}>
              <DetallePedido
                esComputadora={datos.esComputadora}
                idPedido={idSeleccionado}
                nombreDistribuidor={distSeleccionadoModal}
                fecSol={fecSolSeleccionadoModal}
                codigoDistribuidor={coddistSeleccionadoModal}
                cerrarModalClick={cerrarModalClick}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <div id='contenedorListaDist' style={e.listaDistStyle(datos.esComputadora,rol)}>
        <Opciones
          style={{
            
          }}
          textoStyle={
            {
              fontSize: datos.esComputadora ? "1vw":"2.5vw"
            }
          }
          moderno='Distribuidor'
          opciones={distribuidores !== null ? distribuidores : []}
          clave='codUsuario'
          valor={['Nombre', 'ApellidoPaterno']}
          esComputadora={datos.esComputadora}
          onClick={distribuidorSeleccionar}
        />
      </div>
      <div id='contenedorListaDist' style={e.numDistStyle(datos.esComputadora,rol)}>
        Nro. Celular: 
        {celDist !==null && <a style={{color: "#6e0c0c"}} href={"tel:+51"+celDist[distribuidorSeleccionado]}>{celDist[distribuidorSeleccionado]}</a>}
      </div>
      {/* <div id='contenedorListaAyuDist' style={e.listaAyuDistStyle(datos.esComputadora,rol)}>
        <Opciones
          style={{
            
          }}
          textoStyle={
            {
              fontSize: datos.esComputadora ? "1vw":"2.5vw"
            }
          }
          moderno='Ayudante Distribuidor'
          opciones={ayudantes !== null ? ayudantes : []}
          clave='codUsuario'
          valor={['Nombre', 'ApellidoPaterno']}
          esComputadora={datos.esComputadora}
          onClick={ayudanteDistribuidor}
        />
      </div> */}
      { (rol === "ADMINISTRADOR"||rol === "CLIENTE")  &&
      <div style={e.cajaTotalStyle(datos.esComputadora)}>
        <span
          style={{
            ...e.labelTotEnStyle(datos.esComputadora),
            fontSize: datos.esComputadora ? '2vw' : '4vw'
          }}
        >
          Deuda Total : {fg.formatear_moneda(deudaTotal ? deudaTotal : 0)}
        </span>
      </div>
      }
      <div id='contenedorListaPedidos' style={e.contenedorListaPedidosstyle(datos.esComputadora)}>
        {pedidos !== null &&
          pedidos.map(i => {
            return (
              
              <div
                key={i.codPedido}
                id={i.codPedido}
                nro={i.codPedidoNumero}
                nombre={i.nombreUsuarioSolicitante}
                fechasolicitada={i.fechaPedido}
                codigodistribuidor={i.usuarioSolicitante}
                style={{
                  backgroundColor: i.entregado === 'N' ? '#FFF7A9' : '#FCFCFC',
                  ...e.cardPedidostyle(datos.esComputadora, isHovered)
                }}
                onMouseEnter={event => {
                  setIsHovered(true)
                  setIdHovered(event.target.id)
                }}
                onMouseLeave={() => {
                  setIsHovered(false)
                  setIdHovered('')
                }}
                onClick={manejadorOpcionClick}
              >
                <div style={e.contenedorNombreYPedido(datos.esComputadora)}>
                  <span style={e.nomDistStyle(datos.esComputadora)}>
                    {fg.capitalizar_palabra(
                      i.nombreUsuarioSolicitante !== null
                        ? i.nombreUsuarioSolicitante
                        : ''
                    )}
                  </span>
                  <span style={e.nroPedidoStyle(datos.esComputadora)}>
                    Nro. {String(i.codPedidoNumero).padStart(8, '0')}
                  </span>
                </div>
                <span style={e.contUsrRecStyle(datos.esComputadora)}>
                  <label style={e.usrRecStyle(datos.esComputadora)}>Recepcionante:</label>
                  {fg.capitalizar_palabra(
                    i.nombreUsuarioRecepcionante !== null
                      ? i.nombreUsuarioRecepcionante
                      : ''
                  )}
                </span>
                <span style={e.contUsrRecStyle(datos.esComputadora)}>
                  <label style={e.usrRecStyle(datos.esComputadora)}>SECRETARIO:</label>
                  {fg.capitalizar_palabra(
                    i.nombreUsuarioEntrega !== null
                      ? i.nombreUsuarioEntrega
                      : 'No Asignado'
                  )}
                </span>
                <span style={e.contFecSolStyle(datos.esComputadora)}>
                  <label style={e.labFecSolStyle(datos.esComputadora)}>F. Solicitud</label>
                  {fg.formatear_fecha_ddmmyyyy_hora12(i.fechaPedido,"AH")}
                </span>
                <span style={e.contFecSolStyle(datos.esComputadora)}>
                  <label style={e.labFecSolStyle(datos.esComputadora)}>F. Entrega Programada</label>
                  {fg.formatear_fecha_ddmmyyyy_hora12(i.fechaEntregada,"AH")}
                </span>
                <span style={e.contFecSolStyle(datos.esComputadora)}>
                  <label style={e.labFecSolStyle(datos.esComputadora)}>F. Entrega</label>
                  {i.fechaEntregadaReal === null
                    ? '-------'
                    : fg.formatear_fecha_ddmmyyyy_hora12(i.fechaEntregadaReal,"AH")}
                </span>
                <div style={e.contenedorTotales(datos.esComputadora)}>
                  <span style={e.contTotPeStyle(datos.esComputadora)}>
                    <label style={e.labelTotPeStyle(datos.esComputadora)}>Total Pedido</label>
                    {fg.formatear_moneda(i.totalPedido)}
                  </span>
                  <span
                    style={
                      i.entregado === 'S' ? e.contMediumStyle(datos.esComputadora) : e.contTotEnStyle(datos.esComputadora)
                    }
                  >
                    <label style={e.labelTotEnStyle(datos.esComputadora)}>Total Entregado</label>
                    {fg.formatear_moneda(i.totalEntrega)}
                  </span>
                  {i.entregado === 'S' && (
                    <span style={e.contTotEnStyle(datos.esComputadora)}>
                      <label style={e.labelTotEnStyle(datos.esComputadora)}>Total Pagado</label>
                      {fg.formatear_moneda(i.montoPagado)}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    ...e.contenedorTotales(datos.esComputadora),
                    gridTemplateColumns: '30% 60% 10%'
                  }}
                >
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    
                    <Boton
                      style={{
                        backgroundColor: i.deuda !== 0 ? '#F52424' : 'transparent',
                        borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                        padding: datos.esComputadora ? '0.4vw' : '1vw'
                      }}
                      textoStyle={{
                        color: 'white',
                        width: '100%',
                        fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                      }}
                      texto={i.deuda !== 0
                        ? 'Saldo: ' + fg.formatear_moneda(i.deuda)
                        : ''}
                      tipo='submit'
                      tonalidad='0'
                      estaProcesando={false}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {rol === 'ADMINISTRADOR' &&
                      i.entregado === 'S' &&
                      i.deuda !== 0 && (
                        <>
                          <Boton
                            style={{
                              backgroundColor: i.deuda !== 0 ? '#8f0000' : 'transparent',
                              borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                              padding: datos.esComputadora ? '0.4vw' : '1.5vw',
                              margin: datos.esComputadora ? "0 0.2vw 0 0.2vw":"0 1vw 0 1vw"
                            }}
                            textoStyle={{
                              color: 'white',
                              width: '100%',
                              fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                            }}
                            texto='Generar Pago'
                            tipo='submit'
                            tonalidad='20'
                            estaProcesando={false}
                            onClick={(e)=>{
                              e.stopPropagation()
                              setModalGenerarPago(prevState => !prevState)
                              setCodigoPedidoEliminar(
                                e.currentTarget.parentNode.parentNode.parentNode.id
                              )
                              setNombreUsuarioPedido(
                                e.currentTarget.parentNode.parentNode.parentNode.getAttribute(
                                  'nombre'
                                )
                              )
                            }

                            }
                          />
                        </>
                      )}
                    {i.entregado === 'S' && (
                      <Boton
                            style={{
                              backgroundColor: '#8f0000',
                              borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                              padding: datos.esComputadora ? '0.4vw' : '1.5vw',
                              margin: datos.esComputadora ? "0 0.2vw 0 0.2vw":"0 1vw 0 1vw"
                            }}
                            textoStyle={{
                              color: 'white',
                              width: '100%',
                              fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                            }}
                            texto='Ver Pagos'
                            tipo='submit'
                            tonalidad='20'
                            estaProcesando={false}
                            onClick={e => {
                              e.stopPropagation()
                              setModalPago(prevState => !prevState)
                              setCodigoPedidoEliminar(
                                e.currentTarget.parentNode.parentNode.parentNode.id
                              )
                              setNombreUsuarioPedido(
                                e.currentTarget.parentNode.parentNode.parentNode.getAttribute(
                                  'nombre'
                                )
                              )
                            }
                            }
                          />
                    )}
                  </div>

                  {(i.totalEntrega === 0 || rol === 'ADMINISTRADOR') && (
                    <button
                      style={e.botonEliminarStyle(datos.esComputadora)}
                      onClick={e => {
                        e.stopPropagation()
                        setEliminar(true)
                        setCodigoPedidoEliminar(
                          e.currentTarget.parentNode.parentNode.id
                        )
                        setNroPedido(e.currentTarget.parentNode.parentNode.getAttribute("nro"))
                      }}
                    >
                      <img
                        src={borrar}
                        style={e.imagenBotonEliminarStyle(datos.esComputadora)}
                      />
                    </button>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default ListarPedidos
