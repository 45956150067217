import styled from "styled-components";

export const contraseniaCSS = (esComputadora)=>({
    position: 'absolute',
    right: '3vw',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    fontSize: esComputadora ? "1.3vw":"3vw",
})

export const inputTextCSS = (esComputadora) =>({
    padding: esComputadora ? "2vw 1.5vw 0.25vw 1vw":"4vw 3vw 0.5vw 2vw",
    height: "auto",
    width: "100%", /* Utiliza el ancho completo menos el espacio para el label */
    border: esComputadora ? "0.1vw solid #ccc":"0.3vw solid #ccc",
    borderRadius: esComputadora ? '0.5vw':"1vw",
    fontSize: esComputadora ? "1.3vw":"3vw",
    boxSizing: "border-box",
    backgroundColor:"white"
})

export const inputTextDateCSS = (esComputadora) =>({
    padding: esComputadora ? "2vw 1.5vw 0.25vw 1vw":"4vw 3vw 0.5vw 2vw",
    height: "auto",
    width: "100%", /* Utiliza el ancho completo menos el espacio para el label */
    border: esComputadora ? "0.1vw solid #ccc":"0.3vw solid #ccc",
    borderRadius: esComputadora ? '0.5vw':"1vw",
    fontSize: esComputadora ? "1.3vw":"2.5vw",
    boxSizing: "border-box",
    color: 'transparent',
    backgroundColor:"white"
})


export const inputFieldDateCSS = (esComputadora)=>({
    padding: esComputadora ? "2vw 1.5vw 0.25vw 1vw":"4vw 3vw 0.5vw 2vw",
    height: "auto",
    width: "100%", /* Utiliza el ancho completo menos el espacio para el label */
    border: esComputadora ? "0.2vw solid #ccc":"0.4vw solid #ccc",
    borderRadius: esComputadora ? '0.5vw':"1vw",
    fontSize: esComputadora ? "1vw":"2.5vw",
    color: "#333",
    boxSizing: "border-box",
})

export const inputLabelCSS = (esComputadora,textoStyle)=>({
    position: "absolute",
    top: "50%",
    left: esComputadora ? "1vw":"2.5vw", /* Espacio para el label como un porcentaje del ancho del contenedor */
    transform: "translateY(-50%)",
    color: "#999",
    pointerEvents: "none",
    transition: "0.2s",
    fontSize: esComputadora ? "1vw":"2.5vw",
    ...textoStyle
})

export const inputLabelActivoCSS = (esComputadora,textoStyle)=>({
    top: "20%",
    fontSize: esComputadora ? "0.8vw":"2.0vw",
    color: "#333",
    ...textoStyle
})