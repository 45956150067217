import React, { useState, useEffect } from 'react'
import Input from './../../Elementos/Input'
import LogoVitopan from './../../Imagenes/LogoVitopan.png'
import { iniciarSesion, validarSesion } from '../../Api/Api'
import { useNavigate } from 'react-router-dom'
import './Principal.css'
import MenuOpciones from './MenuOpciones/MenuOpciones'
import BarraSuperior from './BarraSuperior/BarraSuperior'
import ContenidoCentral from './ContenidoCentral/ContenidoCentral'

function Principal () {
  const [esComputadora, setesComputadora] = useState(
    window.innerWidth > window.innerHeight
  )
  const [diferenciaDimensiones, setDifDim] = useState(0.0)
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null)
  const [subOpcionSeleccionada, setSubOpcionSeleccionada] = useState(null)
  
  useEffect(() => {
    const handleResize = () => {
      setesComputadora(window.innerWidth > window.innerHeight)
    }
    if(window.innerWidth > window.innerHeight){
        setDifDim(window.innerWidth-window.innerHeight)
      }else{
        setDifDim(window.innerHeight-window.innerWidth)
      }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const contenedorPrincipalStyle = {
    display: 'grid',
    gridTemplateRows: 'repeat(20, 1fr)',
    gridTemplateColumns: 'repeat(20, 1fr)',
    gap: '0px', // Espacio entre las celdas del grid
    backgroundColor:'gray',
    height: esComputadora ? '100vh':'100vh', // Ocupa toda la altura de la pantalla
    width: '100vw',
    boxSizing: 'border-box'
  }

  const contenedorBarraSuperiorStyle = {
    display: esComputadora ? 'inline-block':'block',
    gridArea: esComputadora ? '1 / 2 / 2 / 21':'1 / 1 / 2 / 21',
   
    backgroundColor: '#D9D3C6',
    width: esComputadora ? '100%':'100%',
    height: esComputadora ? '10vh':'10vh', // Ocupa toda la altura de la pantalla
    
}

  const contenedorMenuStyle = {
    display: esComputadora ? 'inline-block':'flex',
    gridArea: esComputadora ? '1 / 1 / 21 / 2':'10 / 1 / 21 / 21',
    backgroundColor: 'rgb(110, 12, 12)',
    width: esComputadora ? '5vw':'100%', // Ancho ajustable
    height: esComputadora ? '100vh':'10vh',
  }

  const contenedorCentralStyle = {
    display: esComputadora ? 'inline-block':'block',
    gridArea: esComputadora ? '2 / 2 / 21 / 21':'2 / 1 / 2 / 21',
    //justifySelf: 'end',
    backgroundColor: 'white',
    width: esComputadora ? '95vw':'100%',
    height: esComputadora ? '90vh':'80vh'
  }

  const establecerOpcion = (opcion) =>{
    setOpcionSeleccionada(opcion);
  }

  return (
    <div id='contenedorPrincipal' style={contenedorPrincipalStyle}>
      <div id='contenedorBarraSuperior' style={contenedorBarraSuperiorStyle}>
        <BarraSuperior esComputadora={esComputadora}/>
      </div>
      <div id='contenedorMenu' style={contenedorMenuStyle}>
        <MenuOpciones establecerOpcion={establecerOpcion} esComputadora={esComputadora}/>
      </div>
      <div id='contenedorCentral' style={contenedorCentralStyle}>
        <ContenidoCentral esComputadora={esComputadora} opcion={opcionSeleccionada}/>
      </div>
    </div>
  )
}

export default Principal
