import React, { useState, useEffect } from 'react'
import * as e from './InputCSS'
import * as fg from './../Api/FuncionesGlobales'

function Input (param) {
  const [isFocused, setIsFocused] = useState(false)
  const [isContent, setIsContent] = useState(false)
  const [inputValue, setInputValue] = useState(param.valorInicial)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  useEffect(() => {
    if (param.valorInicial !== '') {
      //setIsFocused(true)
      setInputValue(param.valorInicial)
      setIsContent(true)
    } else {
      setIsFocused(false)
      setInputValue('')
      setIsContent(false)
    }
  }, [param.valorInicial])

  useEffect(() => {
    if (param.limpiar) {
      setInputValue('')
      setIsContent(false)
    }
  }, [param.limpiar])

  const handleBlur = evento => {
    setIsFocused(false)
    if (inputValue.toString().trim() === '') setIsContent(false)
    else {
      if (
        inputValue.length <=
        (param.maxDigitos ? param.maxDigitos : fg.maxDigitosInput())
      ) {
        if (param.onBlur) param.onBlur(evento)
        setInputValue(evento.currentTarget.value)
      }
    }
  }

  const handleChange = evento => {
    if (
      evento.target.value.toString().length <=
      (param.maxDigitos ? param.maxDigitos : fg.maxDigitosInput())
    ) {
      if (evento.target.value.toString().length !== '') {
        setIsFocused(true)
        setIsContent(true)
      }
      if (param.onChange) param.onChange(evento)
      setInputValue(evento.currentTarget.value)
      console.log(evento.currentTarget.value)
    }
  }

  const inputCSS = {
    ...(param.tipo === 'date'
      ? inputValue === ''
        ? e.inputTextDateCSS(param.esComputadora)
        : e.inputFieldDateCSS(param.esComputadora)
      : e.inputTextCSS(param.esComputadora)),
    ...param.style,
    ...(isFocused
      ? {
          border: param.esComputadora
            ? '0.1vw solid black'
            : '0.2vw solid black',
          boxShadow: 'inset 0 0 0.4vw green',
          outline: 'none'
        }
      : {
          boxShadow: 'none'
        }),
    ...(param.deshabilitado
      ? {
          backgroundColor: '#CCCCCC',
          ...param.deshabilitadoStyle
        }
      : {}),
    
  }

  const listaValores = ['checkbox', 'radio', 'file', 'submit', 'button']

  return (
    <>
      {listaValores.includes(param.tipo) ? (
        <>
          <input
            type={param.tipo ? param.tipo : ''}
            id={param.id ? param.id : ''}
            placeholder={param.antiguo ? param.antiguo : ''}
            style={param.style ? param.style : {}}
            disabled={param.deshabilitado ? true : false}
          />
          {param.moderno !== '' && (
            <label
              id='notexto'
              htmlFor='inputField'
              className={`input-label ${
                isFocused || inputValue.trim() !== '' ? 'active' : ''
              }`}
            >
              {param.moderno}
            </label>
          )}
        </>
      ) : (
        <div
          style={{
            width: '100%',
            padding: '0',
            margin: '0',
            position: 'relative',
            gridArea: param.ubicacion ? param.ubicacion : ''
          }}
        >
          <input
            type={param.tipo}
            id={param.id}
            onFocus={() => {
              setIsFocused(true)
              setIsContent(true)
            }}
            ref={param.referencia}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={param.antiguo ? param.antiguo : ''}
            style={inputCSS}
            disabled={param.deshabilitado}
            value={inputValue}
            autoComplete={param.autoComplete ? 'current-password' : ''}
            {...(param.tipo === 'password' &&
              isPasswordVisible && { type: 'text' })}
            className={param.className}
          />
          {param.tipo === 'password' && (
            <div
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible)
              }}
              style={e.contraseniaCSS(param.esComputadora)}
            >
              {isPasswordVisible ? '👁️' : '🔒'}
            </div>
          )}
          {param.moderno !== '' && (
            <label
              style={{
                ...e.inputLabelCSS(param.esComputadora, param.textoStyle),
                ...(isContent ? e.inputLabelActivoCSS(param.esComputadora,param.textoStyle) : {})
              }}
            >
              {param.moderno}
            </label>
          )}
        </div>
      )}
    </>
  )
}

export default Input
